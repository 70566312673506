import { useQuery } from "@tanstack/react-query";

import { analyticsRepository } from "repositories/analytics/analytics.repository";
import { ANALYTICS_QUERY_KEYS } from "repositories/analytics/keys";

export const useGetUpcomingSettlements = () => {
  return useQuery({
    queryKey: ANALYTICS_QUERY_KEYS.GET_UPCOMING_SETTLEMENTS,
    queryFn: async () => {
      const response = await analyticsRepository.getUpcomingSettlements();

      if (!response.success && "error" in response) {
        analyticsRepository.sentryLogError({
          error: response.error.message,
          feature: "useGetUpcomingSettlements",
          functionName: "getUpcomingSettlementsQuery",
          data: JSON.stringify(response.error),
        });
      }

      return response;
    },
  });
};
