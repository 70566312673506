import { Repository } from "repositories/_base/repository";
import type { ApiResponse } from "repositories/_base/repository.types";
import type { GenerateOtpRequest } from "repositories/user/types";

class UserRepository extends Repository {
  constructor() {
    super({
      gateway: "/users",
    });
  }

  public async getInfo() {
    return this._http
      .get<
        ApiResponse<{
          firstName: string;
          lastName: string;
          phoneNumber: string;
          email: string;
        }>
      >("/personal_info")
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async generateOtp(request: GenerateOtpRequest) {
    return this._http
      .patch("/generate_otp", request)
      .then(this.handleResponse)
      .catch(this.handleError);
  }
}

export const userRepository = new UserRepository();
