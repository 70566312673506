import type { FilledInputClasses, Theme } from "@mui/material";
import type { OverridesStyleRules } from "@mui/material/styles/overrides";

import { colorsV2 } from "constants/colors";
import { typography } from "constants/typography";

export const MuiFilledInputStyleOverrides = (
  isDark?: boolean,
): Partial<
  OverridesStyleRules<keyof FilledInputClasses, "MuiFilledInput", Theme>
> => {
  return {
    root: {
      border: `1px solid ${colorsV2.border[2]}`,
      borderRadius: "0.8rem",
      paddingLeft: "1.2rem",
      paddingRight: "1.2rem",
      backgroundColor: "unset",
      height: "5.6rem",
      "&.MuiInputBase-sizeSmall": {
        height: "4rem",

        input: {
          ...typography.body.medium,
        },
      },
      "&:before": {
        all: "unset",
      },
      "&:after": {
        all: "unset",
      },
      "&:hover": {
        borderColor: colorsV2.border[1],
        backgroundColor: "unset",
      },
      "&.MuiAutocomplete-inputRoot": {
        height: "100%",
        minHeight: "5.6rem",
        paddingBottom: "0.8rem",
        "&.Mui-focused": {},
        ".MuiAutocomplete-input": {
          marginTop: "0rem",
          padding: "revert !important",
          "&::placeholder": {
            opacity: 1,
          },
        },
        ".MuiAutocomplete-endAdornment": {
          top: "50%",
          transform: "translateY(-50%)",
        },
        ".MuiChip-root": {
          ...typography.body.small,
          color: colorsV2.text[1],
          backgroundColor: colorsV2.surface[3],
          padding: "0.2rem 0rem 0.2rem 0.8rem",
          height: "2.4rem",
          ".MuiChip-label": {
            padding: "0rem",
          },
          ".MuiChip-deleteIcon": {
            margin: "unset",
          },
        },
      },
      "&.Mui-focused": {
        backgroundColor: "unset",
        borderColor: colorsV2.border[4],
      },
      "&.Mui-focused:after": {
        backgroundColor: "unset",
        borderBottom: "unset",
      },
      "&.Mui-error": {
        // borderColor: colorsV2.error[1],
        borderColor: isDark ? colorsV2.dark.error : colorsV2.error[1],
      },
      "&.MuiInputBase-colorWarning": {
        borderColor: colorsV2.accent.yellow[1],
        backgroundColor: colorsV2.accent.yellow[3],
      },
      "&:hover:not(.Mui-disabled):before": {
        borderBottom: "unset",
      },
      "&.Mui-disabled": {
        backgroundColor: colorsV2.surface[2],
        borderColor: colorsV2.border[2],
      },
      "&.Mui-disabled:hover": {
        borderColor: colorsV2.border[2],
        backgroundColor: colorsV2.surface[2],
      },
    },
    input: {
      padding: "unset !important",
      caretColor: colorsV2.primary[1],
      color: isDark ? colorsV2.dark.text[1] : colorsV2.text[1],
      ...typography.body.large,
      "&::placeholder": {
        opacity: 1,
        color: isDark ? colorsV2.dark.text[3] : colorsV2.text[3],
      },
      "&:focus": {
        backgroundColor: "unset",
      },
      "&:-webkit-autofill": {
        "-webkit-background-clip": "text",
        "background-clip": "text",
        backgroundColor: "transparent !important",
        WebkitBoxShadow: isDark ? "0 0 0 100px transparent inset" : "none",
        WebkitTextFillColor: isDark ? colorsV2.dark.text[1] : colorsV2.text[1],
      },
      "&:-webkit-autofill:focus": {
        backgroundColor: "transparent !important",
        WebkitBoxShadow: isDark ? "0 0 0 100px transparent inset" : "none",
      },
    },
    multiline: {
      alignItems: "flex-start",
      height: "10.8rem",
      overflowY: "auto",
    },
  };
};

export const MuiFormHelperTextStyleOverrides = (isDark?: boolean) => ({
  root: {
    color: isDark ? colorsV2.dark.text[3] : colorsV2.text[3],
    letterSpacing: "unset",
    ...typography.body.small,

    "&.Mui-error": {
      color: isDark ? colorsV2.dark.error : colorsV2.error[1],
    },

    // CUSTOM CLASS (BlazeHelperText): NOT PART OF MUI
    "&.BlazeHelperText": {
      color: colorsV2.text[3],
    },
  },
});

export const MuiFormLabelStyleOverrides = (isDark?: boolean) => ({
  root: {
    "&.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-filled.Mui-error":
      {
        color: isDark ? colorsV2.dark.error : colorsV2.error[1],
      },
  },
});
