import { NorthEast, Pending, SouthWest } from "design_system/Icons";

export const activityTypeMappings = {
  // DEBIT CARD
  payment_fee: "Payment fee",
  payment_vat: "Payment fee VAT",
  settlement: "Settlement to your bank",
  vat: "VAT",
  payout: "Payout sent",
  ultra_fee: "Ultra fee",
  card_top_up: "Transfer to card balance",
  dispute_fee: "Dispute fee",
  premium_fee: "Premium fee",
  ultra_fee_vat: "Ultra fee VAT",
  dispute_fee_vat: "Dispute fee VAT",
  premium_fee_vat: "Premium fee VAT",
  disputed_payment: "Payment dispute received",
  fraudulent_refund: "Payment refunded",
  refund: "Payment refunded",
  invoice_repayment: "Invoice repayment",
  card_fee: "Active cards fee",
  card_fee_vat: "Card fee VAT",
  early_settlement_fee: "Early settlement fee",
  early_settlement_fee_vat: "Early settlement fee VAT",
  enterprise_fee: "Enterprise fee",
  enterprise_fee_vat: "Enterprise fee VAT",
  duplicate_settlement_reversal: "Duplicate settlement reversed",
  api_integration_support: "API integration support",
  starter_fee: "Starter fee",
  cash_withdrawal_fee: "ATM withdrawal fee",
  international_payout: "International payout sent",
  expedited_verification_fee: "Expedited verification fee",
  international_payout_fee: "International payout fee",
  payout_fee: "Payout fee",
  payout_fee_vat: "Payout fee VAT",
  hold_reversal_fee: "Hold reversal fee",
  hold_reversal_fee_vat: "Hold reversal fee VAT",

  // CREDIT CARD
  cashback: "Cashback",
  account_top_up: "Balance top up",
  account_top_up_for_credit_card_repayment:
    "Account top up for credit card repayment",
  payment: "Payment received",
  payout_canceled: "Payout reversed",
  card_top_up_canceled: "Cards transfer reversed",
  dispute_won: "Dispute won",
  dispute_fee_reversal: "Dispute fee reversed",
  vat_credit_adjustment: "VAT credit adjustment",
  cancelled_dispute: "Dispute withdrawn",
  referral_bonus: "Referral bonus",
  card_balance_transfer: "Transfer from card balance",
  negative_balance_payment: "Negative balance payment",
  dispute_resolved: "Dispute resolved",
  fee_reversal: "Fee reversal",
  settlement_reversal: "Settlement reversal",
  advance_card_funding: "Advance Card funding",
  advance_card_repayment: "Advance Card repayment",
  advance_card_repayment_fee: "Advance Card transaction fee",
  advance_card_repayment_late_fee: "Advance card repayment late fee",
  credit_card_funding: "Credit card funding",
  credit_card_repayment: "Credit card repayment",
  credit_card_repayment_fee: "Credit card repayment fee",
  credit_card_repayment_late_fee: "Credit card repayment late fee",
  credit_card_repayment_fee_reversal: "Credit card repayment fee reversal",
  credit_card_repayment_late_fee_reversal:
    "Credit card repayment late fee reversal",
  transfer_from_card_balance: "Transfer from card balance",
  transfer_from_cashback_balance: "Transfer from cashback balance",
  card_dispute_advance: "Card dispute advance",

  // COMMON FOR CREDIT AND DEBIT CARD
  transfer: "Balance transfer",
  other: "Other",
  starting_balance: "Starting balance",
};

export const activityStatusMappings = {
  on_the_way: {
    label: "Incoming",
    color: "blue",
    Icon: Pending,
  },
  credited: {
    label: "Credited",
    color: "green",
    Icon: SouthWest,
  },
  debited: {
    label: "Debited",
    color: "pink",
    Icon: NorthEast,
  },
};

export const activityTransactionTypeMappings = {
  debit: "Debit",
  credit: "Credit",
};
