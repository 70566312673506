import { Divider } from "@mui/material";
import { useContext } from "react";
import { useDispatch } from "react-redux";

import { OpenInNew } from "design_system/Icons";
import SideSheet from "design_system/Sidesheet/Sidesheet";
import { Tag } from "design_system/Tag";

import { useAppSelector } from "store";

import { colorsV2 } from "constants/colors";
import { SideSheetContext } from "design_system/Sidesheet/SideSheetContext";
import type { TagColors } from "design_system/Tag/typeDefs";
import { onCloseTransaction } from "features/Wallet/WalletV2/reducer/WalletSlice";
import {
  activityStatusMappings,
  activityTransactionTypeMappings,
  activityTypeMappings,
} from "../constants/activityMapping";

export const TransactionDetailSidesheet = () => {
  const { closeSideSheet } = useContext(SideSheetContext);

  const { transaction } = useAppSelector((store) => store.wallet);
  const dispatch = useDispatch();

  const status = activityStatusMappings[transaction.data.status];
  const displayInfo = activityTypeMappings[transaction.data.breakdownType];

  const handleCloseSidesheet = () => {
    closeSideSheet();
    dispatch(onCloseTransaction());
  };

  return (
    <SideSheet>
      <SideSheet.Navbar>
        <div className="flex justify-between">
          <Tag
            color={status.color as TagColors}
            label={status.label}
            Icon={status.Icon}
          />
          <SideSheet.Close onClose={handleCloseSidesheet} />
        </div>
      </SideSheet.Navbar>
      <SideSheet.Header>
        <div className="flex flex-col">
          <SideSheet.Description>{displayInfo}</SideSheet.Description>
          <SideSheet.Display>
            {transaction.data?.amountFormatted}
          </SideSheet.Display>
        </div>
      </SideSheet.Header>
      <Divider />
      <SideSheet.Content>
        <>
          <section className="flex flex-col gap-8 py-8">
            <span className="label-large text-text-color-02">
              Activity details
            </span>

            <div>
              <div className="grid grid-cols-2 py-8">
                <span className="body-large text-text-color-03">Type</span>
                <span className="body-large">
                  {activityTransactionTypeMappings[transaction.data.type]}
                </span>
              </div>

              <div className="grid grid-cols-2 py-8">
                <span className="body-large text-text-color-03">Date</span>
                <span className="body-large">{transaction.data.date}</span>
              </div>

              <div className="grid grid-cols-2 py-8">
                <span className="body-large text-text-color-03">Amount</span>
                <span className="body-large">
                  {transaction.data.amountFormatted}
                </span>
              </div>

              {transaction.data?.reference?.type !== "payment" &&
                transaction.data?.reference?.type !== "settlement" &&
                transaction.data?.reference && (
                  <div className="grid grid-cols-2 py-8">
                    <span className="body-large text-text-color-03">
                      Reference ID
                    </span>
                    <span className="body-large">
                      {transaction.data.reference.id}
                    </span>
                  </div>
                )}
            </div>
          </section>
          <section className="flex flex-col gap-8 py-8">
            {transaction.data?.reference?.type === "payment" && (
              <>
                <span className="label-large text-text-color-02">
                  Payment details
                </span>

                <div>
                  <div className="grid grid-cols-2 py-8">
                    <span className="body-large text-text-color-03">
                      Payment ID
                    </span>
                    <span className="body-large flex items-center gap-6">
                      {transaction.data.reference.id}
                      <OpenInNew
                        size="14"
                        fill={colorsV2.text[3]}
                        className="mt-4 cursor-pointer"
                        onClick={() =>
                          window.open(transaction.data.reference.link)
                        }
                      />
                    </span>
                  </div>
                </div>
              </>
            )}

            {transaction.data.reference?.type === "settlement" && (
              <div>
                <span className="label-large text-text-color-02">
                  Settlement details
                </span>

                <div className="grid grid-cols-2 py-8">
                  <span className="body-large text-text-color-03">
                    Settlement ID
                  </span>
                  <span className="body-large flex items-center gap-6">
                    {transaction.data.reference.id}
                    <OpenInNew
                      size="14"
                      fill={colorsV2.text[3]}
                      className="mt-4 cursor-pointer"
                      onClick={() =>
                        window.open(transaction.data.reference.link)
                      }
                    />
                  </span>
                </div>
              </div>
            )}
          </section>
        </>
      </SideSheet.Content>
    </SideSheet>
  );
};
