import { useMutation } from "@tanstack/react-query";
import { accountsRepository } from "repositories/accounts/accounts.repository";
import { ACCOUNTS_QUERY_KEYS } from "repositories/accounts/keys";

export type ValidateEmailMutation = Parameters<
  typeof accountsRepository.validateEmail
>;

export const useValidateEmail = () => {
  return useMutation({
    mutationKey: ACCOUNTS_QUERY_KEYS.VALIDATE_EMAIL,
    mutationFn: async (data: {
      email: ValidateEmailMutation[0];
      params: ValidateEmailMutation[1];
    }) => {
      const response = await accountsRepository.validateEmail(
        data.email,
        data.params,
      );

      if (!response.success && "error" in response) {
        accountsRepository.sentryLogError({
          error: response.error.message,
          feature: "Verification",
          functionName: "validateEmail",
          data: JSON.stringify(data),
        });

        return response;
      }

      return response;
    },
  });
};
