import { colorsV2 } from "constants/colors";
import type { SvgIconProps } from "types/Icon";

export const Chat = ({
  size,
  fill = colorsV2.text[1],
  ...rest
}: SvgIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <title>Chat</title>
      <g id="Name=chat, Size=24">
        <path
          id="Vector"
          d="M6.00004 14.025H13.825V12.525H6.00004V14.025ZM6.00004 10.775H18V9.275H6.00004V10.775ZM6.00004 7.525H18V6.025H6.00004V7.525ZM1.85059 22.1495V3.5538C1.85059 3.09218 2.01946 2.69191 2.35721 2.35298C2.69498 2.01403 3.09385 1.84455 3.55384 1.84455H20.4462C20.9079 1.84455 21.3081 2.01403 21.6471 2.35298C21.986 2.69191 22.1555 3.09218 22.1555 3.5538V16.4462C22.1555 16.9062 21.986 17.3051 21.6471 17.6428C21.3081 17.9806 20.9079 18.1495 20.4462 18.1495H5.85059L1.85059 22.1495ZM5.20059 16.4462H20.4462V3.5538H3.55384V18.2429L5.20059 16.4462Z"
          fill={fill}
        />
      </g>
    </svg>
  );
};
