"use client";

import type { SearchParamsToast } from "./schema";

export const useSetSearchParamsToastRoot = () => {
  const toSearchParams = (data: SearchParamsToast) => {
    const searchParams = new URLSearchParams(document.location.search);
    searchParams.set("leading_element", data.leading_element);
    searchParams.set("title", data.title);
    searchParams.set("variant", data.variant);
    return searchParams;
  };

  const resetSearchParams = () => {
    const searchParams = new URLSearchParams(document.location.search);
    searchParams.delete("leading_element");
    searchParams.delete("title");
    searchParams.delete("variant");

    window.history.replaceState({}, "", `${document.location.pathname}`);
  };

  const setSearchParamsToastRoot = (toast: SearchParamsToast) => {
    const searchParams = toSearchParams(toast);
    window.history.replaceState(
      {},
      "",
      `${document.location.pathname}?${searchParams.toString()}`,
    );
  };

  return { setSearchParamsToastRoot, toSearchParams, resetSearchParams };
};
