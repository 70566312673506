import Card from "components/ui/Card";
import { PageHeader } from "./PageHeader";

import { SearchParamsToastRoot } from "components/SearchParamsToast/SearchParamsToastRoot";
import routes from "constants/routes";
import { Skeleton } from "design_system/Skeleton";
import { useGetActiveAccount } from "repositories/bank-accounts/_hooks/useGetActiveAccount";
import { useMerchantInfo } from "repositories/merchant/_hooks/use-merchant-info";

export const BankContainer = () => {
  const { data: merchantInfo } = useMerchantInfo();
  const { data, isLoading } = useGetActiveAccount();

  const handleGoToPage = () => {
    if (!merchantInfo.currentMerchant.isMerchantVerified) {
      window.location.href = routes.MANAGE.VERIFICATION.PERSONAL_DETAILS();

      return;
    }

    window.location.href = routes.MANAGE.ACCOUNT_SETTINGS.BANK.UPDATE_IBAN();
  };

  return (
    <>
      <PageHeader />
      <SearchParamsToastRoot />

      <div className="account-settings">
        <div className="mx-auto" style={{ maxWidth: "714px" }}>
          <Card hasBorder classes="text-16">
            {/* IBAN */}
            <div className="mb-24 space-y-4 border-black-50 border-b pb-24">
              <p className="font-medium">IBAN</p>
              <div className="flex flex-row items-center gap-16 pt-8">
                {isLoading ? (
                  <Skeleton />
                ) : (
                  <>
                    <p className="text-black-300">{data?.iban}</p>
                    <span
                      className="material-icons-round cursor-pointer text-18"
                      onClick={handleGoToPage}
                    >
                      edit
                    </span>
                  </>
                )}
              </div>
            </div>

            {/* BANK NAME */}
            <div className="mb-24 space-y-4 border-black-50 border-b pb-24">
              <p className="font-medium">Bank name</p>
              <p className="text-black-300">
                {isLoading ? <Skeleton /> : data?.bankName}
              </p>
            </div>

            {/* ACCOUNT HOLDER NAME */}
            <div className="space-y-4">
              <p className="font-medium">Account holder name</p>
              <p className="text-black-300">
                {isLoading ? <Skeleton /> : data?.accountHolderName}
              </p>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};
