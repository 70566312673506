"use client";

import { useMutation } from "@tanstack/react-query";
import { bankAccountsRepository } from "repositories/bank-accounts/bank-accounts.repository";
import { BANK_ACCOUNTS_QUERY_KEYS } from "repositories/bank-accounts/keys";
import type { UpdateBankAccount } from "repositories/bank-accounts/types";

export const useUpdateBankAccount = () => {
  return useMutation({
    mutationKey: BANK_ACCOUNTS_QUERY_KEYS.UPDATE_BANK_ACCOUNT,
    mutationFn: async (data: UpdateBankAccount) => {
      const response = await bankAccountsRepository.updateBankAccount(data);

      if (!response.success && "error" in response) {
        bankAccountsRepository.sentryLogError({
          error: response.error.message,
          feature: "useUpdateBankAccount",
          functionName: "updateBankAccount",
          data: JSON.stringify(data),
        });
      }

      return response;
    },
  });
};
