import { type Ref, forwardRef, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha";

import { SentryLoggingService } from "init/SentryLoggingService";

const CLIENT_SITE_KEY = process.env.REACT_RE_CAPTCHA_SITE_KEY;

export type ReCaptchaClientProps = {
  type?: "invisible" | "normal";
  siteKey?: string;
};

export const ReCaptchaClient = forwardRef(
  (props: ReCaptchaClientProps, ref: Ref<ReCAPTCHA>) => {
    /**
     * Handle errors from reCAPTCHA
     */
    const handleError = useCallback((error: unknown) => {
      SentryLoggingService.captureException(
        "[reCAPTCHA][onErrored] Error occurred for Google ReCaptcha",
        {
          feature: "ReCaptcha",
          function: "onErrored",
          data: JSON.stringify(error),
        },
      );
    }, []);

    return (
      <ReCAPTCHA
        ref={ref}
        onErrored={handleError}
        size={props.type || "invisible"}
        sitekey={props.siteKey || CLIENT_SITE_KEY}
      />
    );
  },
);

ReCaptchaClient.displayName = "ReCaptchaClient";

export const reCaptchaButtonAttrs = {
  "data-callback": "onSubmit",
  "data-action": "submit",
  "data-sitekey": CLIENT_SITE_KEY,
};

export type { ReCAPTCHA };
