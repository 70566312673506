import { useQuery } from "@tanstack/react-query";

import { SETTLEMENTS_KEYS } from "repositories/settlements/keys";
import { settlementsRepository } from "repositories/settlements/settlements.repository";

export const useGetSettlement = (id: string) => {
  return useQuery({
    queryKey: SETTLEMENTS_KEYS.SETTLEMENT(id),
    queryFn: async () => {
      const response = await settlementsRepository.getSettlement(id);

      if (!response.success && "error" in response) {
        settlementsRepository.sentryLogError({
          error: response.error.message,
          feature: "Settlements",
          functionName: "getSettlement",
          data: JSON.stringify(response.error),
        });
      }

      return response;
    },
  });
};
