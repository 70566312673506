import { Switch as MuiSwitch, type SwitchProps } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import type React from "react";
import type { JSXElementConstructor } from "react";

import { colorsV2 } from "constants/colors";
import { customTheme } from "design_system/shared/theme/theme";
import type { SvgIconProps } from "types/Icon";

export interface ISwitchProps extends SwitchProps {
  description?: string;
  Icon?: JSXElementConstructor<SvgIconProps>;
  id: string;
  title?: string;
  titleIcon?: React.ReactNode;
}

export const Switch = ({
  description,
  Icon,
  id,
  title,
  titleIcon,
  ...props
}: ISwitchProps) => {
  const outerTheme = useTheme();

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <div id={`${id}-wrapper`}>
        <div className="flex w-full items-center justify-between gap-12">
          <div className="flex items-center gap-12">
            {Icon && <Icon size="18" fill={colorsV2.text[1]} />}
            {title && (
              <label
                htmlFor={id}
                className="label-medium md:label-large flex items-center gap-10"
              >
                {title}
                {titleIcon}
              </label>
            )}
          </div>
          <MuiSwitch id={id} disableRipple {...props} />
        </div>

        {description && (
          <p className="body-small md:body-medium mt-8 block text-text-color-02 md:mt-16">
            {description}
          </p>
        )}
      </div>
    </ThemeProvider>
  );
};
