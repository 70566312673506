import { memo, useEffect, useMemo } from "react";

import { Snackbar, snack } from "design_system/Snackbar";

import type { SnackVariants } from "design_system/Snackbar/typeDefs";
import { searchParamsToastSchema } from "./schema";
import { useSetSearchParamsToastRoot } from "./useSetSearchParamsToastRoot";

export const SearchParamsToastRoot = memo(() => {
  const { resetSearchParams } = useSetSearchParamsToastRoot();

  const snackDetail = useMemo(() => {
    const searchParams = new URLSearchParams(document.location.search);

    const details = searchParamsToastSchema.safeParse({
      leading_element: searchParams.get("leading_element"),
      title: searchParams.get("title"),
      variant: searchParams.get("variant"),
    });

    if (!details.success) {
      return null;
    }

    return details;
  }, [document.location.search]);

  useEffect(() => {
    if (snackDetail?.success && snackDetail.data) {
      snack({
        title: snackDetail.data.title,
        variant: snackDetail.data.variant as SnackVariants,
        leadingElement: snackDetail.data.leading_element,
      });
    }
  }, [snackDetail]);

  useEffect(() => {
    const timer = setTimeout(() => {
      resetSearchParams();
    }, 200);

    return () => clearTimeout(timer);
  }, []);

  return <Snackbar />;
});

SearchParamsToastRoot.displayName = "SearchParamsToastRoot";
