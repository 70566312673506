import { Tabs as MuiTabs, type TabsProps } from "@mui/material";
import MuiTab from "@mui/material/Tab"; // Avoid name clash with Tabs.Tab
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { createContext, useContext } from "react";
import { cn } from "utils/classNames";

import { useScreenDimension } from "hooks/useScreenDimension";

import { customTheme } from "design_system/shared/theme/theme";
import { isAppPlatform } from "helpers/isAppPlatform";

export interface ITabProps extends Omit<TabsProps, "classes"> {
  children: React.ReactNode;
  size?: "md" | "lg";
  classes?: string;
  noUnderline?: boolean;
  topPosition?: string | number;
}

const TabContext = createContext({ size: "lg" });

export const Tabs = ({
  children,
  classes,
  size = "lg",
  noUnderline = false,
  topPosition,
  ...props
}: ITabProps) => {
  const { isMobile } = useScreenDimension();
  const outerTheme = useTheme();

  // NOTE: The below isAppPlatform logic is if the user is on the Mamo Business mobile app,
  // Then set the top position to 0px and ignore the isMobile condition since that's for web specifically
  const defaultTopPosition = !isAppPlatform()
    ? isMobile
      ? "112px"
      : "128px"
    : "0px";

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <TabContext.Provider value={{ size }}>
        <div
          className={cn("sticky top-0 bg-surface-1", {
            "border-border-3 border-b px-16 pt-16":
              size === "md" && !noUnderline,
            "border-border-3 border-b px-40 pt-24":
              size === "lg" && !noUnderline,
            "p-0": noUnderline && (size === "md" || size === "lg"),
            [`${classes}`]: !!classes,
          })}
          style={{
            zIndex: 1000,
            top: topPosition ? topPosition : defaultTopPosition,
          }}
        >
          <MuiTabs {...props} variant="scrollable" scrollButtons={false}>
            {children}
          </MuiTabs>
        </div>
      </TabContext.Provider>
    </ThemeProvider>
  );
};

const BlazeTab = ({ ...props }) => {
  const { size } = useContext(TabContext);

  return (
    <MuiTab
      {...props}
      disableRipple
      className={cn("", {
        "Mui-md": size === "md",
        "Mui-lg": size === "lg",
      })}
    />
  );
};

Tabs.Tab = BlazeTab;
