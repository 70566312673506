import { sharedTooltipClasses } from "design_system/shared/constant";
import { TooltipWithDrawer } from "./TooltipWithDrawer";
import { TooltipWithoutDrawer } from "./TooltipWithoutDrawer";

// todo(muj): use JSXElementConstructor<IIcon> for Icon type as the next update

export type TooltipDrawerProps = {
  enableForMobile: boolean;
  title?: string;
  description: string;
};
export interface ITooltipProps {
  /** Content required inside the tooltip */
  content: string;
  /** Optional: Where the positioning of the content needs to be. Default is bottom */
  contentPosition?: "top" | "right" | "bottom" | "left";
  /** Optional: Width of the tooltip content. Default is auto. Should be defined in `rem` values */
  contentWidth?: string;
  drawerProps?: TooltipDrawerProps;
  /** Icon should be part of the Icon directory in our application */
  Icon: React.ReactNode;
}

/**
 * @deprecated use tooltips instead (design_system/tooltips/base.tsx)
 */
export const Tooltip = ({
  content,
  contentPosition,
  contentWidth = "auto",
  drawerProps,
  Icon,
}: ITooltipProps) => {
  const tooltipContentProps = {
    sideOffset: 10,
    className: sharedTooltipClasses(contentPosition),
    style: { width: contentWidth },
    side: contentPosition,
  };

  if (drawerProps?.enableForMobile) {
    return (
      <TooltipWithDrawer
        content={content}
        drawerProps={drawerProps}
        Icon={Icon}
        tooltipContentProps={tooltipContentProps}
      />
    );
  }

  return (
    <TooltipWithoutDrawer
      content={content}
      Icon={Icon}
      tooltipContentProps={tooltipContentProps}
    />
  );
};
