import { useMutation } from "@tanstack/react-query";

import { accountsRepository } from "repositories/accounts/accounts.repository";
import { ACCOUNTS_QUERY_KEYS } from "repositories/accounts/keys";

export const useVerifyCode = () => {
  return useMutation({
    mutationKey: ACCOUNTS_QUERY_KEYS.VERIFY_CODE,
    mutationFn: async (otp: string) => {
      const response = await accountsRepository.verifyPhoneNumber(otp);

      if (!response.success && "error" in response) {
        accountsRepository.sentryLogError({
          error: response.error.message,
          feature: "accounts",
          functionName: "verifyPhoneNumber",
          data: JSON.stringify(response.error),
        });
      }

      return response;
    },
  });
};
