import type { ISettlementBalanceProps } from "interfaces/overview/IOverview";
import type { GetAnalyticsParams } from "repositories/analytics/types";

import { Repository } from "repositories/_base/repository";
import type { ApiResponse } from "repositories/_base/repository.types";
import type { AnalyticsDataPoint } from "types/insights/analytics-data-point";

class AnalyticsRepository extends Repository {
  constructor() {
    super({
      gateway: "analytics",
      baseURL: "/manage/",
    });
  }

  public async getAnalytics(params: GetAnalyticsParams) {
    return this._http
      .get<ApiResponse<AnalyticsDataPoint[]>>("/", { params })
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async getUpcomingSettlements() {
    return this._http
      .get<ApiResponse<ISettlementBalanceProps>>("/insights", {
        baseURL: "/manage/api/v1",
      })
      .then(this.handleResponse)
      .catch(this.handleError);
  }
}

export const analyticsRepository = new AnalyticsRepository();
