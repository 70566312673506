import { Repository } from "repositories/_base/repository";
import type { ApiResponse } from "repositories/_base/repository.types";
import type { UpdateBankAccount } from "repositories/bank-accounts/types";

class BankAccountsRepository extends Repository {
  constructor() {
    super({
      gateway: "/bank_accounts",
    });
  }

  public async getActiveAccount() {
    return this._http
      .get<
        ApiResponse<{
          iban: string;
          bankName: string;
          accountHolderName: string;
        }>
      >("/active_account")
      .then(this.handleResponse)
      .catch(this.handleError);
  }

  public async updateBankAccount(data: UpdateBankAccount) {
    return this._http
      .patch<ApiResponse<UpdateBankAccount>>("/", {
        otp: data.otp,
        iban: data.iban,
        account_holder_name: data.accountHolderName,
      })
      .then(this.handleResponse)
      .catch(this.handleError);
  }
}

export const bankAccountsRepository = new BankAccountsRepository();
