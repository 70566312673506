import { routes } from "constants/routes";
import type { PermissionsState } from "reducers/redux/Permissions/permissionsSlice";
import type { SignedUpFor } from "types/SignedUpFor";
import type { DashboardNavigationItem } from "./DashboardNavigation.types";

export const setupVerificationPrimaryItem = (): DashboardNavigationItem[] => [
  {
    hasPermission: true,
    Icon: "Build",
    id: "setup-tab",
    label: "Setup",
    url: routes.MANAGE.SETUP.VERIFICATION_FAILED(),
  },
];

export const topNavigationItems = (
  permissions: PermissionsState,
  signedUpFor: SignedUpFor,
): DashboardNavigationItem[] => [
  // ==============================================
  // FINANCES TAB
  // ==============================================
  {
    hasPermission: permissions.access.includes("finances-tab"),
    Icon: "Wallet",
    id: "finances-tab",
    label: "Finances",
    children: [
      {
        hasPermission: permissions.access.includes("accounts"),
        Icon: "Toll",
        id: "accounts",
        label: "Accounts",
        url: routes.MANAGE.WALLET.INDEX(),
      },
      {
        hasPermission: permissions.access.includes("settlements"),
        Icon: "AccountBalance",
        id: "settlements",
        label: "Settlements",
        url: routes.MANAGE.SETTLEMENTS.INDEX(),
      },
      {
        hasPermission: permissions.access.includes("payouts"),
        Icon: "SendMoney",
        id: "payouts",
        label: "Payouts",
        url: routes.MANAGE.DISBURSEMENTS.INDEX(),
      },
    ],
  },
  // ==============================================
  // PAYMENTS TAB
  // ==============================================
  {
    hasPermission: permissions.access.includes("payments-tab"),
    Icon: "Payments",
    id: "payments-tab",
    label: "Payments",
    children: [
      {
        hasPermission: permissions.access.includes("payments"),
        Icon: "Payments",
        id: "payments",
        label: "Payments",
        url: routes.MANAGE.PAYMENTS.INDEX(),
      },
      {
        hasPermission: permissions.access.includes("payment-links"),
        Icon: "Link",
        id: "payment-links",
        label: "Payment links",
        url: routes.MANAGE.LINKS.INDEX().concat("?status=active"),
      },
      {
        hasPermission: permissions.access.includes("subscriptions"),
        Icon: "AutoRenew",
        id: "subscriptions",
        label: "Subscriptions",
        url: routes.MANAGE.SUBSCRIPTIONS.INDEX(),
      },
      {
        hasPermission: permissions.access.includes("invoices"),
        Icon: "ReceiptLong",
        id: "invoices",
        label: "Invoices",
        url: routes.MANAGE.INVOICES.INDEX(),
      },
      {
        hasPermission: permissions.access.includes("pos"),
        Icon: "Store",
        id: "pos",
        label: "In-store payments",
        url: routes.MANAGE.POS.INDEX(),
      },
    ],
  },
  // ==============================================
  // CARDS TAB
  // ==============================================
  signedUpFor === "payments_only"
    ? {
        hasPermission: permissions.access.includes("cards-tab"),
        Icon: "CreditCard",
        id: "cards-tab",
        label: "Cards",
        url: routes.MANAGE.CARDS.INDEX(),
      }
    : {
        hasPermission: permissions.access.includes("cards-tab"),
        Icon: "CreditCard",
        id: "cards-tab",
        label: "Cards",
        children: [
          {
            hasPermission: permissions.access.includes("cards"),
            Icon: "CreditCard",
            id: "cards",
            label: "Cards",
            url: routes.MANAGE.CARDS.INDEX(),
          },
          {
            hasPermission: permissions.access.includes("transactions"),
            Icon: "History",
            id: "transactions",
            label: "Transactions",
            url: routes.MANAGE.CARDS.TRANSACTIONS.INDEX(),
          },
          {
            hasPermission: permissions.access.includes("cardholders"),
            Icon: "Group",
            id: "cardholders",
            label: "Cardholders",
            url: routes.MANAGE.CARDS.CARD_HOLDER.INDEX(),
          },
          {
            hasPermission: permissions.access.includes("balance"),
            Icon: "Toll",
            id: "balance",
            label: "Balance",
            url: routes.MANAGE.CARDS.BALANCE(),
          },
        ],
      },
  // ==============================================
  // EXPENSES TAB
  // ==============================================
  {
    hasPermission: permissions.access.includes("expenses-tab"),
    Icon: "Invoice",
    id: "expenses-tab",
    label: "Expenses",
    url: routes.MANAGE.EXPENSES.INDEX(),
  },
  // ==============================================
  // ACCOUNTING TAB
  // ==============================================
  {
    hasPermission: permissions.access.includes("accounting-tab"),
    Icon: "ImportContacts",
    id: "accounting-tab",
    label: "Accounting",
    url: routes.MANAGE.ACCOUNT_SETTINGS.ACCOUNTING.INDEX(),
  },
  // ==============================================
  // INSIGHTS TAB
  // ==============================================
  {
    hasPermission: permissions.access.includes("insights-tab"),
    Icon: "BarChart",
    id: "insights-tab",
    label: "Insights",
    url: routes.MANAGE.INSIGHTS.REVENUE(),
  },
];

export const bottomNavigationItems = (
  permissions: PermissionsState,
): DashboardNavigationItem[] => [
  // ==============================================
  // SETTINGS TAB
  // ==============================================
  {
    hasPermission: permissions.access.includes("settings-tab"),
    Icon: "Settings",
    id: "settings-tab",
    label: "Settings",
    url: routes.MANAGE.ACCOUNT_SETTINGS.INDEX(),
  },
];

export const allNavigationItems = (
  permissions: PermissionsState,
  signedUpFor: SignedUpFor,
): DashboardNavigationItem[] => [
  ...topNavigationItems(permissions, signedUpFor),
  ...bottomNavigationItems(permissions),
];
