import { useMutation } from "@tanstack/react-query";

import { SETTLEMENTS_KEYS } from "repositories/settlements/keys";
import { settlementsRepository } from "repositories/settlements/settlements.repository";

export const useExportSettlements = () => {
  return useMutation({
    mutationKey: SETTLEMENTS_KEYS.SETTLEMENTS_EXPORT,
    mutationFn: async (id: string) => {
      const response = await settlementsRepository.exportAll(id);

      if (!response.success && "error" in response) {
        settlementsRepository.sentryLogError({
          error: response.error.message,
          feature: "Settlements",
          functionName: "exportAll",
          data: JSON.stringify(response.error),
        });
      }

      return response;
    },
  });
};
