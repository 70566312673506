import { cn } from "utils/classNames";

export const sharedTooltipClasses = (
  contentPosition: "top" | "right" | "left" | "bottom",
) => {
  return cn(
    "z-overall flex flex-col items-center rounded-12 bg-black-400 px-16 py-8 text-14 text-white",
    {
      "animate-slide-up": contentPosition === "top",
      "animate-slide-right": contentPosition === "right",
      "animate-slide-down": contentPosition === "bottom",
      "animate-slide-left": contentPosition === "left",
    },
  );
};
