import routes from "constants/routes";

import type { BalanceTransaction } from "../_types/settlement-details";
import {
  allowKindsPointerEvent,
  balancetTransactionSingularMapping,
} from "../index";

export const BalanceTransactionRow = ({
  identifier,
  kind,
  createdAt,
  amountWithCurrency,
  vatWithCurrency,
  balanceTransactionableType,
  balanceTransactionableIdentifier,
}: BalanceTransaction) => {
  const allowPointerEvent =
    allowKindsPointerEvent.includes(kind) &&
    balanceTransactionableType === "Charge";

  const handleOnClick = () => {
    window.location.href = routes.MANAGE.PAYMENTS.SHOW(
      balanceTransactionableIdentifier,
    );
  };

  return (
    //  TABLE ROW
    <div
      onClick={handleOnClick}
      className={`table-container__row table-container__row--settlement-details ${
        allowPointerEvent ? "" : "pointer-events-none"
      }`}
    >
      {/* PAYMENT ID */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              Reference ID
            </p>
            <p className="table-container__data__settlement-details__data">
              {identifier}
            </p>
          </div>
        </div>
      </div>

      {/* TYPE */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              Type
            </p>
            <p className="table-container__data__settlement-details__data">
              {balancetTransactionSingularMapping[kind]}
            </p>
          </div>
        </div>
      </div>

      {/* DATE */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              Date
            </p>
            <p className="table-container__data__settlement-details__data">
              {createdAt}
            </p>
          </div>
        </div>
      </div>

      {/* CUSTOMER */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              Customer
            </p>
            <p className="table-container__data__settlement-details__data"></p>
          </div>
        </div>
      </div>

      {/* AMOUNT */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              Amount
            </p>
            <p className="table-container__data__settlement-details__data">
              {amountWithCurrency}
            </p>
          </div>
        </div>
      </div>

      {/* VAT */}
      <div className="table-container__data-group">
        <div className="table-container__data table-container__data--settlement-details">
          <div className="table-container__data__settlement-details">
            <p className="table-container__data__settlement-details__heading">
              VAT
            </p>
            <p className="table-container__data__settlement-details__data">
              {vatWithCurrency}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
