import axios, {
  type AxiosAdapter,
  type AxiosError,
  type AxiosRequestConfig,
} from "axios";
import routes from "constants/routes";

import {
  cacheAdapterEnhancer,
  throttleAdapterEnhancer,
} from "axios-extensions";

import { generalErrors } from "constants/errorMessages";
import { SentryLoggingService } from "init/SentryLoggingService";

// Create an interface that extends AxiosRequestConfig
export interface ExtendedAxiosRequestConfig extends AxiosRequestConfig {
  unauthorizedRedirectRef?: string;
}

const AXIOS_TIMEOUT = 40000; // 40 seconds - temporary

// Temp wrapper to handle Interceptor errors
function handleInterceptorError(error: any) {
  SentryLoggingService.captureException(error?.message, {
    feature: "axiosClient",
    function: "interceptors.response.use",
    data: JSON.stringify(error?.response?.data),
  });
}

// @deprecated - Use axiosClient instead
axios.create({
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      cacheFlag: "useCache",
    }),
  ),
});

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    handleInterceptorError(error);
    // If API returns 403 status code, redirect users to forbidden page
    if (error?.response?.status === 403) {
      window.location.href = routes.MANAGE.ERRORS.FORBIDDEN();
    }

    if (error?.response?.status === 401) {
      window.location.href = routes.MANAGE.SESSIONS.LOGIN();
    }

    return Promise.reject(error);
  },
);

axios.defaults.timeout = AXIOS_TIMEOUT; // 40 seconds - temporary
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

// To import it
export const axiosClient = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: AXIOS_TIMEOUT, // 40 seconds - temporary
  adapter: throttleAdapterEnhancer(
    cacheAdapterEnhancer(axios.defaults.adapter as AxiosAdapter, {
      cacheFlag: "useCache",
    }),
  ),
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    switch (error?.response?.status) {
      case 403:
        window.location.href = routes.MANAGE.ERRORS.FORBIDDEN();
        break;
      case 401: {
        const loginUrl = new URL(
          routes.MANAGE.SESSIONS.LOGIN(),
          window.location.origin,
        );
        const ref = error.config?.unauthorizedRedirectRef;
        if (ref) {
          loginUrl.searchParams.set("ref", ref);
        }
        window.location.href = loginUrl.toString();
        break;
      }
      default:
        return Promise.reject(error);
    }
  },
);

// Map for possible BE Error Codes
export const AxiosErrorCodesMap = {
  PHONE_NUMBER_INVALID_FORMAT: "PHONE_NUMBER_INVALID_FORMAT",
  INVALID_OTP: "INVALID_OTP",
  RATE_LIMIT_EXCEEDED: "RATE_LIMIT_EXCEEDED",
  PHONE_NUMBER_DUPLICATE: "PHONE_NUMBER_DUPLICATE",

  // Default error code
  SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
} as const;

export type AxiosErrorCodes =
  (typeof AxiosErrorCodesMap)[keyof typeof AxiosErrorCodesMap];

// Check if error is AxiosError
export const isAxiosError = (error: any): error is AxiosError => {
  return axios.isAxiosError(error);
};

// Map error code to snack message
export const mapAxiosErrorToErrorMessage = (
  key: AxiosErrorCodes,
): {
  key: AxiosErrorCodes;
  message: string;
} => {
  switch (key) {
    case "PHONE_NUMBER_INVALID_FORMAT":
      return {
        key: "PHONE_NUMBER_INVALID_FORMAT",
        message: "Please enter a valid phone number",
      };

    case "INVALID_OTP":
      return {
        key: "INVALID_OTP",
        message: "Code is invalid. Please try again.",
      };

    case "RATE_LIMIT_EXCEEDED":
      return {
        key: "RATE_LIMIT_EXCEEDED",
        message: "Maximum number of attempts reached.",
      };

    case "PHONE_NUMBER_DUPLICATE":
      return {
        key: "PHONE_NUMBER_DUPLICATE",
        message: "Phone number already exists.",
      };

    default:
      return {
        key: "SOMETHING_WENT_WRONG",
        message: generalErrors.SOMETHING_WENT_WRONG,
      };
  }
};

//
export const handleAxiosErrorMessage = (error: any) => {
  if (isAxiosError(error)) {
    return mapAxiosErrorToErrorMessage(error.response?.data.error_code);
  }

  if (error instanceof Error) {
    return {
      key: "SOMETHING_WENT_WRONG",
      message: error.message,
    };
  }

  return {
    key: "SOMETHING_WENT_WRONG",
    message: generalErrors.SOMETHING_WENT_WRONG,
  };
};
