import { upperCase } from "lodash";
import { useCallback, useEffect, useState } from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from "@radix-ui/react-dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@radix-ui/react-tabs";
import { ToggleGroup, ToggleGroupItem } from "@radix-ui/react-toggle-group";
import type { MerchantInfoResponse } from "features/PaymentLink/PaymentLinkCreate/shared/useGetMerchantInfo";

import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";
import toast from "react-hot-toast";
import ReactIframeResizer from "react-iframe-resizer-super";

import Button from "components/Button/Button";
import ColorInput from "components/Inputs/ColorInput";
import TextButton from "components/TextButton/TextButton";
import { updateBranding } from "./Hooks/useUpdateBranding";

// Demo

import DemoCardInformation from "components/Demo/DemoCardInformation";
import DemoCustomerDetails from "components/Demo/DemoCustomerDetails";
import DemoPayment from "components/Demo/DemoPayment";
import DemoThankYou from "components/Demo/DemoThankYou";

import FieldMessage from "components/FieldMessage/FieldMessage";
import LoaderBusiness from "components/Loader/LoaderBusiness";
import Toast from "components/ui/Toast";
import { variables } from "config/variables";
import routes from "constants/routes";
import { SentryLoggingService } from "init/SentryLoggingService";
import { useMerchantInfo } from "repositories/merchant/_hooks/use-merchant-info";
// Toast
import { PageHeader } from "./PageHeader";

const iframeResizerOptions = { checkOrigin: false };

const defaultBrandColor = "#3333FF";
const defaultAccentColor = "#FF0066";

export type ContentProps = {
  data: MerchantInfoResponse;
};

const Content = ({ data }: ContentProps) => {
  const { currentLogo, currentBrandColor, currentAccentColor } =
    data.currentMerchant.brandingSettings;
  const { name: businessName } = data.currentMerchant;

  const [brandColor, setBrandColor] = useState<string>(currentBrandColor);
  const [accentColor, setAccentColor] = useState<string>(currentAccentColor);
  const [logo, setLogo] = useState<string | null>(currentLogo);
  const [logoPreview, setLogoPreview] = useState<string | null>(currentLogo);

  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [isLogoError, setIsLogoError] = useState<boolean>(false);
  const [isLogoErrorMessage, setIsLogoErrorMessage] = useState<any>();

  const hasPremiumTrial = data?.currentMerchant?.trialAccess;

  const stylesheet = variables.isProduction
    ? "https://business.mamopay.com/assets/index-preview.css"
    : "https://staging.business.mamopay.com/assets/index-preview.css";

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);

    return false;
  }, []);

  const onDragLeave = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragOver(false);

    return false;
  }, []);

  const handleImageChange = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();

      const newFiles = e.target.files;
      const fileName = newFiles[0].name;
      const fileSize = newFiles[0].size;
      const extension = fileName.split(".").pop();
      const isSupported = ["png", "svg"].includes(extension.toLowerCase());
      if (!isSupported) {
        setIsLogoError(true);
        setIsLogoErrorMessage(
          `You’re trying to upload a ${upperCase(extension)} file. Please use PNG or SVG`,
        );
        setIsDragOver(false);
      } else if (fileSize > 500000) {
        setIsLogoError(true);
        setIsLogoErrorMessage(
          "Please make sure your uploaded logo is under 500 KB",
        );
        setIsDragOver(false);
      } else {
        reader.onload = (e: any) => {
          setLogoPreview(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
        setIsLogoError(false);
        setLogo(e.target.files[0]);
      }
    }
  };

  useEffect(() => {
    const dragInput: any = document.getElementById("js-file-input");
    dragInput?.addEventListener("dragover", onDragOver);

    return () => {
      dragInput?.addEventListener("dragover", onDragOver);
    };
  }, [onDragOver, onDragLeave]);

  const globalThankYouBgColor = "#F2F2F2";
  const [isMobileScreen, setIsMobileScreen] = useState<boolean>(true);

  const onSubmit = async () => {
    try {
      await updateBranding({ brandColor, accentColor, logo });

      toast.success("Changes saved successfully.", {
        duration: 3000,
        id: "changes-saved",
      });
    } catch (e) {
      toast.error("Oops! Something went wrong. Try again later", {
        duration: 3000,
      });
      SentryLoggingService.captureException(e, {
        feature: "[Settings][Branding]",
        file: "BrandingContainer.tsx",
        function: "onSubmit",
        data: JSON.stringify({
          brandColor,
          accentColor,
          logo,
        }),
      });
    }
  };

  const handleReset = async () => {
    setAccentColor(defaultAccentColor);
    setBrandColor(defaultBrandColor);
    setLogo(null);
    setLogoPreview(null);

    try {
      await updateBranding({
        brandColor: defaultBrandColor,
        accentColor: defaultAccentColor,
        logo: null,
      });

      toast.success("Changes reset. Back to square one!", {
        duration: 3000,
        id: "changes-reset",
      });
    } catch (e) {
      console.error(e);
      toast.error("Oops! Something went wrong. Try again later", {
        duration: 3000,
      });
    }
  };

  return (
    <>
      <PageHeader />
      <Toast hasCloseIcon />

      <section className="account-settings">
        <div className="account-settings__branding account-settings__branding--premium-plan">
          {/* CARD */}
          <div className="account-settings__branding__card">
            {/* CARD HEADER */}
            <div className="account-settings__branding__card__header">
              <h3>Customize</h3>

              <div className="account-settings__branding__card__header__controls">
                <Dialog modal>
                  <DialogTrigger asChild>
                    <Button
                      color="secondary"
                      size="md"
                      label="Reset to default"
                    />
                  </DialogTrigger>

                  <DialogPortal>
                    <DialogOverlay className="modal__overlay" />
                    <div className="modal">
                      <DialogContent className="modal__box">
                        <DialogClose className="modal__box__close material-icons-round">
                          close
                        </DialogClose>
                        <div className="modal__box__content">
                          <h1 className="modal__box__content__title">
                            Are you sure?
                          </h1>
                          <p className="modal__box__content__text">
                            Just confirming if you want to revert to the boring
                            look? You can get back to making it pretty any time
                            you'd like.
                          </p>

                          <div className="flex w-full xs:flex-col space-y-12">
                            <DialogClose asChild>
                              <Button
                                color="tertiary"
                                size="lg"
                                label="No, on second thought..."
                                classes="w-full"
                              />
                            </DialogClose>
                            <DialogClose asChild>
                              <Button
                                color="primary"
                                size="lg"
                                label="Yes, reset to default"
                                type="button"
                                classes="w-full"
                                onClick={handleReset}
                              />
                            </DialogClose>
                          </div>
                        </div>
                      </DialogContent>
                    </div>
                  </DialogPortal>
                </Dialog>

                <Button
                  color="primary"
                  size="md"
                  label="Save changes"
                  type="button"
                  onClick={onSubmit}
                />
              </div>
            </div>

            {/* FORM PREVIEW WRAPPER */}
            <div className="account-settings__branding__card__form-preview-wrapper">
              {/* CARD FORM (ALL THE SETTINGS BASICALLY) */}
              <div className="account-settings__branding__card__form">
                {hasPremiumTrial && (
                  <div className="lg:md-32 mb-24 flex space-x-8 rounded-8 bg-yellow-50 px-16 py-12">
                    <i className="material-icons-round text-18 text-yellow-500">
                      star
                    </i>
                    <span className="text-14 text-black-500">
                      This feature will be available until your Premium trial
                      ends.{" "}
                      <a
                        href={routes.MANAGE.PLANS.INDEX()}
                        className="font-medium text-pink-500"
                      >
                        Learn&nbsp;more
                      </a>
                    </span>
                  </div>
                )}
                {/*  LOGO INPUT */}
                <div className="account-settings__branding__card__form__input">
                  <div className="account-settings__branding__card__form__header">
                    <p className="account-settings__branding__card__form__header__heading">
                      Logo
                    </p>

                    <div className="account-settings__branding__tooltip-container">
                      <TooltipProvider>
                        <Tooltip delayDuration={100}>
                          <TooltipTrigger asChild>
                            <span className="account-settings__branding__tooltip-container__info material-icons-round">
                              info
                            </span>
                          </TooltipTrigger>

                          <TooltipPortal>
                            <TooltipContent
                              className="tooltip"
                              style={{ width: "24.4rem" }}
                            >
                              Your logo will show at the top of the payment
                              form. If you don&rsquo;t have a logo, you can
                              upload any icon or keep it empty.
                              <TooltipArrow fill="#26303e" />
                            </TooltipContent>
                          </TooltipPortal>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </div>

                  {/* DRAG AND DROP INPUT */}
                  {logoPreview ? (
                    <div className="form-field">
                      <div className="form-field__control file-uploaded">
                        <i
                          className="material-icons-round file-uploaded-close"
                          onClick={() => {
                            setIsDragOver(false);
                            setLogo(null);
                            setLogoPreview(null);
                          }}
                        >
                          close
                        </i>
                        <img
                          src={logoPreview || ""}
                          alt="Your amazing logo"
                          draggable={false}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="form-field">
                      <>
                        <div
                          className={`form-field__control file-upload ${isDragOver && "file-upload-dragover"}${isLogoError && "file-upload-error"}`}
                        >
                          <div>
                            <input
                              className="form-field__file"
                              type="file"
                              id="js-file-input"
                              accept="image/png, image/svg+xml"
                              onChange={handleImageChange}
                              onDragOver={onDragOver}
                              onDragLeave={onDragLeave}
                            />
                            <i
                              className="material-icons-round file-upload-icon"
                              style={{
                                animation: isDragOver
                                  ? "pulsate 0.75s alternate infinite"
                                  : "",
                              }}
                            >
                              upload_file
                            </i>

                            <div className="space-y-4">
                              <span className="text-16">
                                Drop your file or{" "}
                                <label
                                  htmlFor="js-file-input"
                                  className="relative z-10 text-button text-button--md text-button--primary text-button--solid"
                                >
                                  browse
                                </label>
                              </span>
                              <p className="text-14 text-black-300">
                                Supported file types: PNG, SVG
                              </p>
                              <p className="text-14 text-black-300">
                                Maximum file size: 500 KB
                              </p>
                            </div>
                          </div>
                        </div>
                        {isLogoError && (
                          <FieldMessage
                            messageType="error"
                            icon="error"
                            messageText={isLogoErrorMessage}
                          />
                        )}
                      </>
                    </div>
                  )}
                </div>

                <div className="account-settings__branding__card__form__colors">
                  {/*  BRAND COLOR INPUT */}
                  <div className="account-settings__branding__card__form__input">
                    <div className="account-settings__branding__card__form__header">
                      <p className="account-settings__branding__card__form__header__heading">
                        Brand color
                      </p>

                      <div className="account-settings__branding__tooltip-container">
                        <TooltipProvider>
                          <Tooltip delayDuration={100}>
                            <TooltipTrigger asChild>
                              <span className="account-settings__branding__tooltip-container__info material-icons-round">
                                info
                              </span>
                            </TooltipTrigger>

                            <TooltipPortal>
                              <TooltipContent
                                className="tooltip"
                                style={{ width: "24.4rem" }}
                              >
                                Your brand color will influence the entire
                                payment form. If you don't have one, trust your
                                gut and make it look pretty!
                                <TooltipArrow fill="#26303e" />
                              </TooltipContent>
                            </TooltipPortal>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    </div>

                    <ColorInput
                      inputName="brandColor"
                      colorValue={brandColor}
                      onColorChange={(color) => setBrandColor(color)}
                      inputId="color"
                      pattern="^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$"
                      maxLength={7}
                    />
                  </div>

                  {/*  ACCENT INPUT */}
                  <div className="account-settings__branding__card__form__input">
                    <div className="account-settings__branding__card__form__header">
                      <p className="account-settings__branding__card__form__header__heading">
                        Accent
                      </p>

                      <div className="account-settings__branding__tooltip-container">
                        <TooltipProvider>
                          <Tooltip delayDuration={100}>
                            <TooltipTrigger asChild>
                              <span className="account-settings__branding__tooltip-container__info material-icons-round">
                                info
                              </span>
                            </TooltipTrigger>

                            <TooltipPortal>
                              <TooltipContent
                                className="tooltip"
                                style={{ width: "24.4rem" }}
                              >
                                Minor changes but big impact! Spice up your
                                buttons and other elements with this color.
                                <TooltipArrow fill="#26303e" />
                              </TooltipContent>
                            </TooltipPortal>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    </div>

                    <ColorInput
                      inputName="merchant[accent_color]"
                      colorValue={accentColor}
                      onColorChange={(color) => setAccentColor(color)}
                      inputId="color"
                      pattern="^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$"
                      maxLength={7}
                    />
                  </div>
                </div>
              </div>

              {/* (TABS AND DESKTOPS) CARD LIVE PREVIEW */}
              <div className="account-settings__branding__card__live-preview">
                <Tabs defaultValue="tab-payment">
                  <div className="flex items-center justify-between px-24 xl:px-0 xl:pr-24">
                    <div className="tabs">
                      <TabsList aria-label="Select view" className="tabs__list">
                        <TabsTrigger
                          value="tab-payment"
                          className="tabs__link tabs__link--branding"
                        >
                          Payment
                        </TabsTrigger>
                        <TabsTrigger
                          value="tab-customer-details"
                          className="tabs__link tabs__link--branding"
                        >
                          Customer details
                        </TabsTrigger>
                        <TabsTrigger
                          value="tab-card-info"
                          className="tabs__link tabs__link--branding"
                        >
                          Card information
                        </TabsTrigger>
                        <TabsTrigger
                          value="tab-thank-you"
                          className="tabs__link tabs__link--branding"
                        >
                          Thank you
                        </TabsTrigger>
                      </TabsList>
                    </div>

                    {/* DESKTOP AND MOBILE TOGGLE */}
                    <ToggleGroup type="single" defaultValue="mobile">
                      <div className="account-settings__branding__card__preview-controls">
                        <ToggleGroupItem
                          value="desktop"
                          className={`account-settings__branding__card__preview-controls__link ${
                            !isMobileScreen
                              ? "account-settings__branding__card__preview-controls__link--active"
                              : ""
                          }`}
                          onClick={() => setIsMobileScreen(false)}
                        >
                          <i className="material-icons-round">computer</i>
                        </ToggleGroupItem>

                        <ToggleGroupItem
                          value="mobile"
                          className={`account-settings__branding__card__preview-controls__link ${
                            isMobileScreen
                              ? "account-settings__branding__card__preview-controls__link--active"
                              : ""
                          }`}
                          onClick={() => setIsMobileScreen(true)}
                        >
                          <i className="material-icons-round">phone_iphone</i>
                        </ToggleGroupItem>
                      </div>
                    </ToggleGroup>
                  </div>

                  <TabsContent value="tab-payment" className="tabs__content">
                    <div className="account-settings__branding__card__live-preview__screen">
                      <ReactIframeResizer
                        iframeResizerOptions={iframeResizerOptions}
                        style={
                          isMobileScreen
                            ? {
                                width: "375px",
                                height: "800px",
                                display: "flex",
                                margin: "auto",
                                transition: "width 0.2s linear",
                              }
                            : {
                                width: "100%",
                                height: "800px",
                                display: "flex",
                                margin: "auto",
                                transition: "all 0.2s linear",
                              }
                        }
                      >
                        <head>
                          <link rel="stylesheet" href={stylesheet} />
                          <link
                            href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
                            rel="stylesheet"
                          />
                          <link
                            href="https://assets.mamopay.com/stylesheet.css"
                            rel="stylesheet"
                          />
                        </head>

                        <DemoPayment
                          brandColor={brandColor}
                          accent={accentColor}
                          logo={logoPreview}
                          hasPremium
                          businessName={businessName}
                        />
                      </ReactIframeResizer>
                    </div>
                  </TabsContent>

                  <TabsContent
                    value="tab-customer-details"
                    className="tabs__content"
                  >
                    <div className="account-settings__branding__card__live-preview__screen">
                      <ReactIframeResizer
                        iframeResizerOptions={iframeResizerOptions}
                        style={
                          isMobileScreen
                            ? {
                                width: "375px",
                                height: "800px",
                                display: "flex",
                                margin: "auto",
                                transition: "width 0.2s linear",
                              }
                            : {
                                width: "100%",
                                height: "800px",
                                display: "flex",
                                margin: "auto",
                                transition: "all 0.2s linear",
                              }
                        }
                      >
                        <head>
                          <link rel="stylesheet" href={stylesheet} />
                          <link
                            href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
                            rel="stylesheet"
                          />
                          <link
                            href="https://assets.mamopay.com/stylesheet.css"
                            rel="stylesheet"
                          />
                        </head>

                        <DemoCustomerDetails
                          brandColor={brandColor}
                          accent={accentColor}
                          businessName={businessName}
                        />
                      </ReactIframeResizer>
                    </div>
                  </TabsContent>

                  <TabsContent value="tab-card-info" className="tabs__content">
                    <div className="account-settings__branding__card__live-preview__screen">
                      <ReactIframeResizer
                        iframeResizerOptions={iframeResizerOptions}
                        style={
                          isMobileScreen
                            ? {
                                width: "375px",
                                height: "800px",
                                display: "flex",
                                margin: "auto",
                                transition: "width 0.2s linear",
                              }
                            : {
                                width: "100%",
                                height: "800px",
                                display: "flex",
                                margin: "auto",
                                transition: "all 0.2s linear",
                              }
                        }
                      >
                        <head>
                          <link rel="stylesheet" href={stylesheet} />
                          <link
                            href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
                            rel="stylesheet"
                          />
                          <link
                            href="https://assets.mamopay.com/stylesheet.css"
                            rel="stylesheet"
                          />
                        </head>

                        <DemoCardInformation
                          brandColor={brandColor}
                          accent={accentColor}
                          businessName={businessName}
                        />
                      </ReactIframeResizer>
                    </div>
                  </TabsContent>

                  <TabsContent value="tab-thank-you" className="tabs__content">
                    <div className="account-settings__branding__card__live-preview__screen">
                      <ReactIframeResizer
                        iframeResizerOptions={iframeResizerOptions}
                        style={
                          isMobileScreen
                            ? {
                                width: "375px",
                                height: "800px",
                                display: "flex",
                                margin: "auto",
                                transition: "width 0.2s linear",
                              }
                            : {
                                width: "100%",
                                height: "800px",
                                display: "flex",
                                margin: "auto",
                                transition: "all 0.2s linear",
                              }
                        }
                      >
                        <head>
                          <link rel="stylesheet" href={stylesheet} />
                          <link
                            href="https://fonts.googleapis.com/icon?family=Material+Icons+Round"
                            rel="stylesheet"
                          />
                          <link
                            href="https://assets.mamopay.com/stylesheet.css"
                            rel="stylesheet"
                          />
                        </head>

                        <DemoThankYou
                          brandColor={brandColor}
                          accent={accentColor}
                          businessName={businessName}
                          hasPremium
                        />
                      </ReactIframeResizer>
                    </div>
                  </TabsContent>
                </Tabs>
              </div>
            </div>
          </div>

          {/* (MOBILE) PREVIEW IN BROWSER */}
          <div className="account-settings__branding__card__preview">
            <TextButton color="primary" size="sm" label="Preview in browser" />
          </div>
        </div>

        {/* (MOBILE) RESET/SAVE CONTROLS */}
        <div className="account-settings__branding__controls z-10">
          <Dialog modal>
            <DialogTrigger asChild>
              <Button color="secondary" size="md" label="Reset to default" />
            </DialogTrigger>

            <DialogPortal>
              <DialogOverlay className="modal__overlay" />
              <div className="modal">
                <DialogContent className="modal__box">
                  <DialogClose className="modal__box__close material-icons-round">
                    close
                  </DialogClose>
                  <div className="modal__box__content">
                    <h1 className="modal__box__content__title">
                      Are you sure?
                    </h1>
                    <p className="modal__box__content__text">
                      Just confirming if you want to revert to the boring look?
                      You can get back to making it pretty any time you'd like.
                    </p>

                    <div className="flex w-full xs:flex-col space-y-12">
                      <DialogClose asChild>
                        <Button
                          color="tertiary"
                          size="md"
                          label="No, on second thought..."
                          classes="w-full"
                        />
                      </DialogClose>
                      <DialogClose asChild>
                        <Button
                          color="primary"
                          size="md"
                          label="Yes, reset to default"
                          classes="w-full"
                          type="button"
                          onClick={handleReset}
                        />
                      </DialogClose>
                    </div>
                  </div>
                </DialogContent>
              </div>
            </DialogPortal>
          </Dialog>

          <Button
            color="primary"
            size="md"
            label="Save changes"
            type="button"
            onClick={onSubmit}
          />
        </div>
      </section>
    </>
  );
};

export const BrandingContainer = () => {
  const { data, isLoading } = useMerchantInfo();

  if (isLoading) return <LoaderBusiness />;

  return <Content data={data} />;
};
