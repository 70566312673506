import { useCallback, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "store";

import { setTrigger } from "reducers/redux/ScrollTrigger/scrollTriggerSlice";

export const useScrollTrigger = (bannerRef) => {
  const dispatch = useDispatch();
  const trigger = useAppSelector((state) => state.scrollTrigger.trigger);
  const lastScrollY = useRef(0);

  const handleScroll = useCallback(() => {
    const currentScrollY = Math.round(window.scrollY);
    const scrollingDown = currentScrollY > lastScrollY.current;

    // Force expand when very close to top
    if (currentScrollY < 10) {
      dispatch(setTrigger(false));
      lastScrollY.current = currentScrollY;
      return;
    }

    if (bannerRef?.current) {
      const bannerHeight = bannerRef.current.getBoundingClientRect().height;
      const remainingHeightLeft =
        document.documentElement.scrollHeight -
        window.innerHeight -
        bannerHeight;

      const buffer = 5;
      const currentTrigger = trigger;
      const newTrigger = scrollingDown
        ? currentScrollY >= bannerHeight
        : currentScrollY > bannerHeight / 2;

      if (
        remainingHeightLeft > bannerHeight + 80 &&
        currentTrigger !== newTrigger &&
        Math.abs(currentScrollY - bannerHeight) > buffer
      ) {
        dispatch(setTrigger(newTrigger));
      }
    } else {
      const remainingHeightLeft =
        document.documentElement.scrollHeight - window.innerHeight;
      const threshold = 120;

      const buffer = 5;
      const currentTrigger = trigger;
      const newTrigger = scrollingDown
        ? currentScrollY >= threshold
        : currentScrollY > threshold / 2;

      if (
        remainingHeightLeft > threshold &&
        currentTrigger !== newTrigger &&
        Math.abs(currentScrollY - threshold) > buffer
      ) {
        dispatch(setTrigger(newTrigger));
      }
    }

    lastScrollY.current = currentScrollY;
  }, [dispatch, bannerRef, trigger]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    // Reset trigger when the URL changes
    dispatch(setTrigger(false));
  }, [location, dispatch]);

  return { trigger };
};
