import { SnackVariants } from "design_system/Snackbar/typeDefs";
import { z } from "zod";

export const searchParamsToastSchema = z.object({
  leading_element: z.enum(["icon"]),
  title: z.string(),
  variant: z.enum(Object.values(SnackVariants) as [string, ...string[]]),
});

export type SearchParamsToast = z.infer<typeof searchParamsToastSchema>;
