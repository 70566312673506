import { MasterHeader } from "design_system/MasterHeader";

import { NavigationTabs } from "features/Insights/_components/navigation-tabs";
import { Outlet } from "react-router-dom";

export const InsightsLayout = () => {
  return (
    <>
      <MasterHeader>
        <MasterHeader.Title>Insights</MasterHeader.Title>
      </MasterHeader>
      <NavigationTabs />

      <div className="px-16 pt-24 md:p-40">
        <Outlet />
      </div>
    </>
  );
};
