import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import TextInput from "components/Inputs/TextInput";

import { isAppPlatform } from "helpers/isAppPlatform";
import { handleClose } from "../shared/utils/close";
import { useUpdateIbanForm } from "../useEditBankDetails";

import { useOtp } from "hooks/useOtp";

import { ReactQueryProvider } from "components/Providers";
import { useSetSearchParamsToastRoot } from "components/SearchParamsToast/useSetSearchParamsToastRoot";
import TextButton from "components/ui/TextButton";
import routes from "constants/routes";
import { Button } from "design_system/Button";
import { Snackbar, snack } from "design_system/Snackbar";
import { bankAccountsRepositoryHooks } from "repositories/bank-accounts";
import { updateBankAccountSchema } from "repositories/bank-accounts/types";
import { useMerchantInfo } from "repositories/merchant/_hooks/use-merchant-info";
import { userRepositoryHooks } from "repositories/user";

export const Content = () => {
  const navigate = useNavigate();

  const { data: merchantInfo } = useMerchantInfo();
  const { formControl, fields } = useUpdateIbanForm();
  const { mutateAsync: generateOtp } = userRepositoryHooks.useGenerateOtp();
  const { isDisabled, buttonText, resetTimer } = useOtp();
  const { mutateAsync: updateBankAccount } =
    bankAccountsRepositoryHooks.useUpdateBankAccount();

  const { toSearchParams } = useSetSearchParamsToastRoot();

  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const { control, errors, setError, watch } = formControl;

  const otpInvalid = watch("otp")?.length !== 6;

  const handleResendCode = async () => {
    const response = await generateOtp({
      purpose: "update_bank_account",
    });

    if (response.success) {
      snack({
        title: "Code resent",
        leadingElement: "icon",
        variant: "success",
      });
      resetTimer();
    }
  };

  const onSubmit = async () => {
    setIsVerifying(true);

    const validFields = updateBankAccountSchema.safeParse(fields);

    if (!validFields.success) {
      setIsVerifying(false);

      setError("otp", {
        message: validFields.error.formErrors.fieldErrors.otp.join(", "),
      });

      return;
    }

    const response = await updateBankAccount(validFields.data);

    // In Case of failure
    if (
      !response.success &&
      "error" in response &&
      response.error.key === "INVALID_OTP"
    ) {
      snack({
        title: "Code is invalid. Please try again",
        leadingElement: "icon",
        variant: "critical",
      });
      setIsVerifying(false);
      return;
    }

    const searchParams = toSearchParams({
      leading_element: "icon",
      title: "Bank details have been updated",
      variant: "success",
    });

    setIsVerifying(false);

    window.location.href = `${routes.MANAGE.ACCOUNT_SETTINGS.BANK.INDEX()}?${searchParams.toString()}`;
  };

  useEffect(() => {
    // data is not going to be here when the user refreshes the page so
    // we should take them back to the first step
    if (isEmpty(fields.iban) || isEmpty(fields.accountHolderName)) {
      navigate(routes.MANAGE.ACCOUNT_SETTINGS.BANK.UPDATE_IBAN(), {
        replace: true,
      });
    }
  }, []);

  return (
    <>
      <Snackbar />
      {!isAppPlatform() && (
        <header>
          <button
            type="button"
            className="create-link__close-button material-icons-round"
            onClick={handleClose}
          >
            close
          </button>
        </header>
      )}

      <section className="bg-white pt-48 md:mx-auto md:max-w-3xl md:rounded-16 md:p-48 lg:max-w-5xl">
        <div className="space-y-48">
          <div className="flex flex-col gap-16 text-center">
            <span data-testid="page-title" className="font-bold text-28">
              Enter verification code
            </span>
            <span className="heading-03 text-black-300">
              Enter the code you received at
              <span className="ml-4">
                {merchantInfo?.currentUser?.phoneNumber}
              </span>
            </span>
          </div>

          <div className="space-y-48">
            <Controller
              name="otp"
              control={control}
              defaultValue=""
              render={(props) => (
                <TextInput
                  autoFocus
                  type="number"
                  inputId="otp"
                  labelText="Your 6-digit code"
                  onChange={(value) => {
                    props.onChange(value);
                  }}
                  onBlur={props.onBlur}
                  errors={errors.otp}
                  errorMessage={errors.otp?.message}
                />
              )}
            />

            <div className="flex flex-col items-center gap-24 text-center">
              <Button
                classes="w-full"
                type="button"
                color="primary"
                variant="filled"
                size="lg"
                label={isVerifying ? "Verifying..." : "Continue"}
                onClick={onSubmit}
                loading={isVerifying}
                disabled={otpInvalid}
              />

              <div className="flex items-center justify-center gap-4">
                <span className="body-medium text-text-color-02">
                  Didn’t get the code?
                </span>
                <TextButton
                  color="primary"
                  type="button"
                  size="sm"
                  isDisabled={isDisabled}
                  label={buttonText}
                  onClick={handleResendCode}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const VerificationCode = () => {
  return (
    <ReactQueryProvider>
      <Content />
    </ReactQueryProvider>
  );
};
