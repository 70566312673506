import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { ReactNode } from "react";

import { queryClient } from "config/reactQueryClient";

export type ReactQueryProviderProps = {
  withDevtools?: boolean;
  children: ReactNode;
};

export const ReactQueryProvider = ({
  children,
  withDevtools = false,
}: ReactQueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      {withDevtools && process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      {children}
    </QueryClientProvider>
  );
};
