import { useContext } from "react";

import { Divider } from "components/Divider";
import { Avatar } from "design_system/Avatar";
import SideSheet from "design_system/Sidesheet/Sidesheet";
import { Tag } from "design_system/Tag";

import { SideSheetContext } from "design_system/Sidesheet/SideSheetContext";
import { getTapixAvatar } from "utils/tapixAvatarUtil";
import { transactionStatusMapping } from "./constants/mappings";

export const TransactionSidesheet = ({ record }) => {
  const { closeSideSheet } = useContext(SideSheetContext);

  const showAmountConversions =
    record.amountCurrency !== record.billingAmountCurrency;

  const avatar = getTapixAvatar(record.tapixShop, record.merchantName);

  return (
    <SideSheet>
      <SideSheet.Navbar>
        <div className="flex justify-between md:pl-16">
          <Tag
            Icon={transactionStatusMapping[record.status].Icon}
            color={transactionStatusMapping[record.status].color}
            label={transactionStatusMapping[record.status].label}
          />
          <SideSheet.Close onClose={() => closeSideSheet()} />
        </div>
      </SideSheet.Navbar>
      <SideSheet.Header>
        <div className="flex items-center gap-16">
          {avatar.isIcon && avatar.Icon && (
            <Avatar size="2xl" isIcon Icon={avatar.Icon} />
          )}
          {!avatar.isIcon && avatar.url && (
            <Avatar isIcon={false} size="2xl" url={avatar.url} />
          )}
          <div className="space-y-4">
            <p className="body-large text-text-color-02">
              {record.merchantName}
            </p>
            <p className="display-small">{record.amountFormatted}</p>
          </div>
        </div>
      </SideSheet.Header>
      <Divider />
      <SideSheet.Content>
        <section className="flex flex-col gap-16 break-words">
          {record.status === "Declined" && record.declinedReason && (
            <span className="body-large text-error-1">
              Transaction declined {record.declinedReason}
            </span>
          )}
          <h2 className="label-large text-text-color-02">
            Transaction details
          </h2>
          {showAmountConversions && (
            <div className="grid grid-cols-2">
              <span className="body-large text-text-color-03">
                Amount in AED
              </span>
              <span className="body-large">
                {record.billingAmountFormatted}
              </span>
            </div>
          )}

          {record.cashbackAmountFormatted && record.cashbackPercentage && (
            <div className="grid grid-cols-2">
              <span className="body-large text-text-color-03">
                Cashback earned ({record?.cashbackPercentage}%)
              </span>
              <span className="body-large text-accent-green-01">
                {record.cashbackAmountFormatted}
              </span>
            </div>
          )}

          <div className="grid grid-cols-2">
            <span className="body-large text-text-color-03">Date</span>
            <span className="body-large">{record.createdAt}</span>
          </div>

          {record.cardName && (
            <>
              <div className="grid grid-cols-2">
                <span className="body-large text-text-color-03">Card</span>
                <span className="body-large">
                  {record.cardName}
                  <span className="body-small pl-8 text-text-color-03">
                    {record.cardType === "virtual" ? "Virtual" : "Physical"}
                    {record.cardLast4 ? ` - ${record.cardLast4}` : ""}
                  </span>
                </span>
              </div>

              <div className="grid grid-cols-2">
                <span className="body-large text-text-color-03">
                  Cardholder
                </span>
                <span className="body-large">
                  {record.cardHolderName || "-"}
                </span>
              </div>
            </>
          )}
          <div className="grid grid-cols-2">
            <span className="body-large text-text-color-03">
              Transaction ID
            </span>
            <span className="body-large">{record.identifier}</span>
          </div>

          {record.merchantDetails && (
            <div className="grid grid-cols-2">
              <span className="body-large text-text-color-03">
                Merchant details
              </span>
              <span className="body-large">{record.merchantDetails}</span>
            </div>
          )}
        </section>
      </SideSheet.Content>
    </SideSheet>
  );
};
