import { useGoogleLogin } from "@react-oauth/google";
import isEmpty from "lodash/isEmpty";
import { useEffect, useRef, useState } from "react";
import { useController } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "design_system/Button";
import { InfoCard } from "design_system/InfoCard";
import { TextInput } from "design_system/Inputs/TextInput";
import { BeforeWeSetupModal } from "./BeforeWeSetupModal";

import { useAppSelector } from "store";
import { useGoogleSsoSignUp } from "./hooks/useGoogleSsoSignUp";
import { useSignUp } from "./hooks/useSignUp";
import { useSignUpForm } from "./hooks/useSignUpForm";

import { SentryLoggingService } from "init/SentryLoggingService";
import { updateData } from "reducers/redux/Access/SignUp/signUpSlice";
import type { SignUpMode } from "reducers/redux/Access/SignUp/typeDefs";
import type { SignUpFormProps } from "../shared/typeDefs";

import GoogleLogoSm from "assets/illustrations/logo-google-sm.svg";
import {
  type ReCAPTCHA,
  ReCaptchaClient,
} from "components/re-captcha/re-captcha.client";
import routes from "constants/routes";

const SIGN_UP_RECAPTCHA_SITE_KEY =
  process.env.REACT_RE_CAPTCHA_DASHBOARD_SITE_KEY;

export const SignUpForMamo = () => {
  const reCaptchaClient = useRef<ReCAPTCHA>(null);

  const { control, errors, getValues, isValidEmail } = useSignUpForm();
  const { signUp } = useSignUp();
  const navigate = useNavigate();

  const { isSuccess } = signUp;
  const [showModal, setShowModal] = useState(false);
  const { googleSsoSignUp } = useGoogleSsoSignUp();
  const { errorMessage, sessionExpired } = useAppSelector(
    (state) => state.signUp,
  );
  const dispatch = useDispatch();

  const navigateToVerifyOtp = () => navigate(routes.ONBOARDING.VERIFY_OTP());

  const handleFormFieldBlur = (fieldName: keyof SignUpFormProps) => {
    return () => {
      const isValid = !errors[fieldName];
      if (isValid) {
        dispatch(
          updateData({
            data: {
              ...getValues(),
            },
          }),
        );
      }
    };
  };

  const isContinueButtonDisabled = () => {
    return (
      !!errors.email ||
      isEmpty(getValues().email) ||
      !isValidEmail ||
      signUp.isLoading
    );
  };

  /**
   * Navigate to the verify OTP page
   */
  const continueAction = () => {
    setShowModal(false);
    navigateToVerifyOtp();
  };

  const handleSignUp = async () => {
    const token = await reCaptchaClient?.current?.executeAsync();

    signUp.mutateAsync(token ?? "");
  };

  const handleGoogleSignUp = useGoogleLogin({
    onSuccess: (tokenResponse) => googleSsoSignUp.mutateAsync(tokenResponse),
    onError: (error) =>
      SentryLoggingService.captureException("error", {
        feature: "[SignUp][Google SSO]",
        function: "handleGoogleSignUp",
        file: "SignUpForMamo",
        data: JSON.stringify(error) ?? null,
      }),
  });

  /**
   * Handle the sign up flow based on the mode
   */
  const handleShowModalBeforeSignUp = async (mode: SignUpMode) => {
    dispatch(updateData({ data: { mode } }));

    switch (mode) {
      case "email":
        handleSignUp();
        break;

      case "googleSso":
        handleGoogleSignUp();
        break;

      default:
        return;
    }
  };

  /**
   * Display the modal only when the sign up is successful
   */
  useEffect(() => {
    if (isSuccess) {
      setShowModal(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isEmpty(errorMessage?.email)) {
      setShowModal(false);
    }
  }, [errorMessage]);

  return (
    <div className="space-y-32">
      {sessionExpired && (
        <InfoCard
          showIcon
          variant="critical"
          description="Looks like you've been away for a while. Please sign up again to continue."
        />
      )}

      <div className="flex flex-col gap-32">
        <div className="space-y-8 text-center">
          <h1 className="header-large md:display-medium">Sign up for Mamo</h1>
          <p className="body-medium md:body-large">
            Consolidate payment collection, corporate cards, and expense
            management in one place.
          </p>
        </div>

        <TextInput
          {...useController({ name: "email", control }).field}
          label="Email"
          error={!!errors.email || !isEmpty(errorMessage?.email)}
          helperText={errors.email?.message || errorMessage?.email}
          onBlur={handleFormFieldBlur("email")}
          className="w-full"
        />

        <div className="w-full space-y-16">
          <Button
            variant="filled"
            size="lg"
            label="Continue"
            classes="w-full"
            onClick={() => handleShowModalBeforeSignUp("email")}
            disabled={isContinueButtonDisabled()}
          />

          <div className="flex items-center gap-24">
            <div className="h-1 w-full bg-border-3"></div>
            <span className="body-medium">or</span>
            <div className="h-1 w-full bg-border-3"></div>
          </div>

          <Button
            variant="outlined"
            size="lg"
            label="Continue with Google"
            imgUrl={GoogleLogoSm}
            classes="w-full"
            onClick={() => handleShowModalBeforeSignUp("googleSso")}
          />
        </div>

        <span className="body-large text-center">
          Already have an account?{" "}
          <a href="/manage/sign_in" className="label-large text-primary-1">
            Log in
          </a>
        </span>
      </div>

      <ReCaptchaClient
        ref={reCaptchaClient}
        siteKey={SIGN_UP_RECAPTCHA_SITE_KEY}
      />

      <BeforeWeSetupModal
        showModal={showModal}
        setShowModal={setShowModal}
        continueAction={continueAction}
      />
    </div>
  );
};
