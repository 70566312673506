import { MenuItem } from "@mui/material";
import numeral from "numeral";
import { useEffect, useMemo, useRef, useState } from "react";

import { Accordion, AccordionRoot } from "design_system/Accordion/Accordion";
import { Avatar } from "design_system/Avatar";
import { Dropdown } from "design_system/Dropdown";
import { AccountBalance, ErrorOutline } from "design_system/Icons";
import { UaeFlag } from "design_system/Illustrations/CurrencyFlags";
import { Checkbox } from "design_system/Inputs/Checkbox";
import { Tag } from "design_system/Tag";

import { queryClient } from "config/reactQueryClient";
import { getSingularOrPlural } from "helpers/stringUtils";
import { cn } from "utils/classNames";

export const RepaymentsSidesheet = ({
  records,
  checkedRecords,
  paymentMethod,
  onSelectPaymentMethod,
  onChange,
}) => {
  const contentRef = useRef(null);
  const containerRef = useRef(null);

  const [isScrollable, setIsScrollable] = useState(false);

  const finances: any = queryClient.getQueryData(["getWalletsAndCards"]);

  const aedWallet = finances?.wallets?.find(
    (wallet) => wallet.currency === "AED",
  );

  const availableBalance = aedWallet?.totalBalanceFormatted;

  const totalRepaymentAmount = useMemo(() => {
    if (checkedRecords.length > 0) {
      const total = checkedRecords.reduce(
        (sum, record) => sum + record.totalAmount,
        0,
      );
      return numeral(total).format("0,0.00");
    }
    if (records.length === 1) {
      return records[0].totalAmount;
    }
    return 0;
  }, [checkedRecords, records]);

  useEffect(() => {
    if (!contentRef.current || !containerRef.current) return;

    const checkScrollable = () => {
      const containerHeight = containerRef.current.clientHeight;
      const contentHeight = contentRef.current.scrollHeight;
      setIsScrollable(contentHeight > containerHeight);
    };

    const resizeObserver = new ResizeObserver(checkScrollable);
    resizeObserver.observe(contentRef.current);
    resizeObserver.observe(containerRef.current);

    window.addEventListener("resize", checkScrollable);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("resize", checkScrollable);
    };
  }, []);

  return (
    <section className="flex h-full flex-col gap-16">
      <div
        className={cn(
          "label-large grid flex-shrink-0 grid-cols-2 py-8 font-medium md:text-header-small",
          {
            "pr-0": records.length === 1,
            "pr-18": records.length > 1,
          },
        )}
      >
        <span>Total amount</span>
        <span className="text-right">AED {totalRepaymentAmount}</span>
      </div>

      <div
        ref={containerRef}
        data-testid="repayments-container"
        className={cn("flex flex-col overflow-hidden", {
          "h-full flex-grow": isScrollable,
        })}
      >
        <div
          ref={contentRef}
          data-testid="repayments-content"
          className={cn("overflow-y-auto", {
            "flex-grow": isScrollable,
          })}
        >
          {records.length > 1 ? (
            <AccordionRoot id="repayments-accordion">
              {records.map((record) => {
                const cardRepaymentRemainingDays =
                  record.cardRepaymentRemainingDays;
                let tagColor, tagLabel;

                if (cardRepaymentRemainingDays < 0) {
                  tagColor = "pink";
                  tagLabel = `${Math.abs(cardRepaymentRemainingDays)} ${getSingularOrPlural("day", cardRepaymentRemainingDays)} overdue`;
                } else if (cardRepaymentRemainingDays === 0) {
                  tagColor = "yellow";
                  tagLabel = "Due today";
                }

                return (
                  <Accordion key={record.identifier}>
                    <Accordion.Summary sx={{ padding: "0px" }}>
                      <div className="flex flex-1 items-center justify-between">
                        <div className="flex items-center gap-12">
                          <Checkbox
                            inputId={record.identifier}
                            labelText={record.cardRepaymentDate}
                            checkboxSize="sm"
                            checked={checkedRecords.some(
                              ({ identifier }) =>
                                identifier === record.identifier,
                            )}
                            onChange={(e) =>
                              onChange(
                                record,
                                (e.target as HTMLInputElement).checked,
                              )
                            }
                          />
                          {tagColor && tagLabel && (
                            <Tag
                              color={tagColor}
                              label={tagLabel}
                              Icon={ErrorOutline}
                            />
                          )}
                        </div>

                        <span className="body-large">
                          {record.totalAmountFormatted}
                        </span>
                      </div>
                    </Accordion.Summary>

                    <Accordion.Details>
                      <SingleRepaymentDetail
                        billingAmountFormatted={record.billingAmountFormatted}
                        creditCardFeeAmountFormatted={
                          record.creditCardFeeAmountFormatted
                        }
                        creditCardLateFeeAmountFormatted={
                          record.creditCardLateFeeAmountFormatted
                        }
                      />
                    </Accordion.Details>
                  </Accordion>
                );
              })}
            </AccordionRoot>
          ) : (
            <>
              <div className="body-medium md:body-large grid grid-cols-2 text-text-color-02">
                <span>{records[0]?.cardRepaymentDate}</span>
                <span className="text-right">
                  {records[0]?.totalAmountFormatted}
                </span>
              </div>
              <div className="py-12">
                <SingleRepaymentDetail
                  billingAmountFormatted={records[0]?.billingAmountFormatted}
                  creditCardFeeAmountFormatted={
                    records[0]?.creditCardFeeAmountFormatted
                  }
                  creditCardLateFeeAmountFormatted={
                    records[0]?.creditCardLateFeeAmountFormatted
                  }
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className="space-y-8 pt-24">
        <header className="label-medium md:label-large text-text-color-02">
          Payment method
        </header>
        <Dropdown
          type="select"
          selectProps={{
            defaultValue: "mamo_balance",
            value: paymentMethod,
            onChange: onSelectPaymentMethod,
          }}
        >
          <MenuItem value="mamo_balance">
            <div className="flex items-center gap-8">
              <UaeFlag size="24" />
              <span className="body-large">AED account</span>
              <span className="body-medium text-text-color-02">
                {availableBalance} balance
              </span>
            </div>
          </MenuItem>
          <MenuItem value="bank_transfer">
            <div className="flex items-center gap-8">
              <Avatar size="md" isIcon={true} Icon={AccountBalance} />
              Bank transfer
            </div>
          </MenuItem>
        </Dropdown>
      </div>
    </section>
  );
};

function SingleRepaymentDetail({
  billingAmountFormatted,
  creditCardFeeAmountFormatted,
  creditCardLateFeeAmountFormatted,
}) {
  return (
    <div className="space-y-4">
      <div className="body-small md:body-medium grid grid-cols-2 text-text-color-02">
        <span>Amount</span>
        <span className="text-right">{billingAmountFormatted}</span>
      </div>

      <div className="body-small md:body-medium grid grid-cols-2 text-text-color-02">
        <span>Interest</span>
        <span className="text-right">{creditCardFeeAmountFormatted}</span>
      </div>
      <div className="body-small md:body-medium grid grid-cols-2 text-error-1">
        <span>Late fee</span>
        <span className="text-right">{creditCardLateFeeAmountFormatted}</span>
      </div>
    </div>
  );
}
