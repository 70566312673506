import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { type InferType, object, string } from "yup";

import { Button } from "design_system/Button";
import { TextInput } from "design_system/Inputs/TextInput";
import { snack } from "design_system/Snackbar";

import TextButton from "components/TextButton/TextButton";
import {
  CHANNEL_TO_NAME,
  type Channel,
} from "components/TwoFactorAuthentication/_shared/schema";

import { routes } from "constants/routes";
import { preventAlphaKeyDown, preventAlphaOnPaste } from "helpers/numberUtils";
import { useOtp } from "hooks/useOtp";

import { useSendCode } from "repositories/accounts/_hooks/use-send-code";
import { useVerifyCode } from "repositories/accounts/_hooks/use-verify-code";

const _formSchema = object({
  code: string()
    .required("Code is invalid. Please try again")
    .test("len", "Must be exactly 6 characters", (val) => val?.length === 6)
    .typeError("Code is invalid. Please try again.")
    .nullable(),
});

type FormSchema = InferType<typeof _formSchema>;

export const CodeVerificationForm = () => {
  const [searchParams] = useSearchParams();
  const phoneNumber = searchParams.get("phone_number");
  const channel = searchParams.get("channel") as Channel;
  const { buttonText, isDisabled, resetTimer } = useOtp();

  const {
    data,
    isLoading: isVerifyingCode,
    mutateAsync: verifyCode,
  } = useVerifyCode();

  const { mutateAsync: sendCode, isLoading: isSendingCode } = useSendCode();

  const errorMessage = useMemo(() => {
    if (data && !data.success && "error" in data) {
      return data.error;
    }
  }, [data]);

  const {
    errors,
    control,
    formState: { isValid, isDirty },
    getValues,
  } = useForm<FormSchema>({
    resolver: yupResolver(_formSchema),
    defaultValues: {
      code: null,
    },
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = async () => {
    const response = await verifyCode(getValues("code"));

    if (response.success && response.data.is_create_password) {
      // BE redirects to set password
      window.location.href = routes.MANAGE.FIRST_TIME_LOGIN.PASSWORD();
    }
  };

  const resendCode = () => {
    sendCode({
      phoneNumber,
      channel,
    }).then(() => {
      resetTimer();
      snack({
        title: `Code resent via ${CHANNEL_TO_NAME[channel]}`,
        variant: "success",
        leadingElement: "icon",
      });
    });
  };

  return (
    <form className="space-y-24">
      <Controller
        control={control}
        name="code"
        render={(props) => (
          <TextInput
            maxLength={6}
            {...props}
            type="number"
            onKeyDown={preventAlphaKeyDown}
            onPaste={preventAlphaOnPaste}
            label="Your 6-digit code"
            error={!!errors.code?.message || !!errorMessage?.message}
            helperText={errors.code?.message || errorMessage?.message}
          />
        )}
      />

      <div className="flex flex-col gap-24">
        <Button
          size="lg"
          type="button"
          classes="w-full"
          onClick={onSubmit}
          variant="filled"
          loading={isVerifyingCode}
          disabled={!isValid || !isDirty || isVerifyingCode}
          label={isVerifyingCode ? "Verifying..." : "Continue"}
        />

        <div className="flex items-center justify-center gap-4">
          <span className="body-medium text-text-color-02">
            Didn’t get the code?
          </span>
          <TextButton
            color="primary"
            type="button"
            size="sm"
            isDisabled={isDisabled || isSendingCode}
            label={buttonText}
            onClick={resendCode}
          />
        </div>
      </div>
    </form>
  );
};
