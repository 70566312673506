import type { UseQueryResult } from "@tanstack/react-query";

import type IPaginationMeta from "interfaces/IPaginationMeta";
import type { activityTypeMappings } from "./DetailPage/WalletActivity/constants/activityMapping";

export const WalletTransactionStatus = {
  credited: "credited",
  on_the_way: "on_the_way",
  debited: "debited",
};

export type WalletTransactionStatus =
  (typeof WalletTransactionStatus)[keyof typeof WalletTransactionStatus];

export const WalletTransactionType = {
  credit: "credit",
  debit: "debit",
};

export type WalletTransactionType =
  (typeof WalletTransactionType)[keyof typeof WalletTransactionType];

export type WalletAmountByDate = {
  date: string;
  amount: number;
  amountFormatted: string;
};

export type WalletDetail = {
  id: string;
  currency: string;
  isoCode: string;
  autoSettlementEnabled: boolean;
  totalBalance: number;
  totalBalanceFormatted: string;
  incomingBalance: number;
  incomingBalanceFormatted: string;
  availableBalance: number;
  availableBalanceFormatted: string;
  incomingAmountByDates: WalletAmountByDate[];
};

export type WalletDetailEnrichedWithMeta = {
  // BANNER
  bannerDetail: {
    type: "settlement" | "negativeTotalBalance";
    variant: "info" | "critical";
    title: string;
    description: string;
  };
  // BALANCES
  availableBalance: {
    isNegative: boolean;
    isZero: boolean;
    subText: string;
  };
  incomingBalance: {
    subText: string;
  };
  totalBalance: {
    isNegative: boolean;
    isZero: boolean;
  };
  // WALLET
  selectedWallet: WalletDetail;
};

export type DebitCardDetail = {
  type: "debit";
  id: string;
  currency: string;
  totalBalance: number;
  totalBalanceFormatted: string;
};

export type CreditCardDetail = {
  type: "credit";
  id: string;
  currency: string;
  balance: number;
  balanceFormatted: string;
  creditLimit: number;
  creditLimitFormatted: string;
};

export type WalletsAndCardsData = {
  wallets: WalletDetail[];
  cards: Array<DebitCardDetail | CreditCardDetail>;
  cashback_wallet?: WalletDetail;
};
export type WalletsAndCardsDataApiResponse = {
  data: WalletsAndCardsData;
};

export type WalletsAndCardsApiResponse = UseQueryResult<
  WalletsAndCardsData,
  unknown
>;
export type WalletDetailResponse = UseQueryResult<
  WalletDetailEnrichedWithMeta,
  unknown
>;

export type WalletTransactionReference = {
  id: string;
  type: "payment" | "settlement" | "balance_transaction";
  amount: number;
  amountFormatted: string;
  link: string;
};

export type WalletTransaction = {
  activityName: string;
  amount: number;
  amountFormatted: string;
  balance: number;
  balanceFormatted: string;
  breakdownType: keyof typeof activityTypeMappings;
  currency: string;
  date: string;
  reference: WalletTransactionReference | null;
  id: string;
  status: WalletTransactionStatus;
  type: WalletTransactionType;
};

export type TransactionsWithPagination = {
  data: WalletTransaction[];
  paginationMeta: IPaginationMeta;
};

export interface WalletSlice {
  // TRANSACTION
  transaction: {
    data: WalletTransaction;
  };
}

export type HeaderPageProps = { wallet: WalletDetail };
export type TransactionsTableProps = {
  transactions?: TransactionsWithPagination;
};
export type WalletActivityProps = { wallet: WalletDetailEnrichedWithMeta };
export type WalletOverviewProps = { wallet: WalletDetailEnrichedWithMeta };

export type WalletV2HeaderProps = {
  isTotalBalanceNegative: boolean;
  totalBalance: string;
};
export type WalletV2Props = { isFreelancer: boolean };

export type useGetWalletTransactionsParams = {
  wallet: WalletDetail;
  configs: {
    page: number;
  };
};

export type WalletTabs = "Overview" | "Activity";
