import { type ReactNode, createContext } from "react";

import type { Theme } from "types/Root";

interface FullPageLayoutProviderProps {
  hideDividers?: boolean;
  isMobile: boolean;
  theme?: Theme;
  children: ReactNode;
}

export const fullPageLayoutInitState = {
  hideDividers: false,
  isMobile: false,
  theme: "light",
};

// Create a context to manage the state of the FullPageLayout
export const FullPageLayoutContext = createContext(fullPageLayoutInitState);

export const FullPageLayoutProvider = ({
  isMobile,
  hideDividers,
  theme,
  children,
}: FullPageLayoutProviderProps) => {
  return (
    <FullPageLayoutContext.Provider value={{ isMobile, hideDividers, theme }}>
      {children}
    </FullPageLayoutContext.Provider>
  );
};
