import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import * as RadixTooltip from "@radix-ui/react-tooltip";
import { useState } from "react";

import { IconButton } from "design_system/IconButton";
import { Help } from "design_system/Icons";

import { customTheme } from "design_system/shared/theme/theme";
import type { TooltipDrawerProps } from "./Tooltip";

// todo(muj): use JSXElementConstructor<IIcon> for Icon type as the next update
export interface ITooltipWithDrawerProps {
  content: string;
  drawerProps: TooltipDrawerProps;
  Icon: React.ReactNode;
  tooltipContentProps: {
    sideOffset: number;
    className: string;
    style: {
      width: string;
    };
    side: "top" | "right" | "bottom" | "left";
  };
}

export const TooltipWithDrawer = ({
  content,
  drawerProps,
  Icon,
  tooltipContentProps,
}: ITooltipWithDrawerProps) => {
  const outerTheme = useTheme();

  const [open, setOpen] = useState(false);

  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <div className="flex lg:hidden">
        <IconButton Icon={Help} onClick={toggleDrawer(true)} />
        <SwipeableDrawer
          anchor="bottom"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
        >
          {/* Custom MUI class added to handle in the theme.ts */}
          <header className="MuiDrawer-dragHandle-header">
            <div className="MuiDrawer-dragHandle"></div>
          </header>

          <div className="space-y-18">
            {drawerProps.title && (
              <p className="header-medium">{drawerProps.title}</p>
            )}
            <p className="body-large text-text-color-02">
              {drawerProps.description}
            </p>
          </div>
        </SwipeableDrawer>
      </div>

      <div className="hidden lg:flex lg:items-center">
        <RadixTooltip.Provider>
          <RadixTooltip.Root delayDuration={100}>
            <RadixTooltip.Trigger type="button">{Icon}</RadixTooltip.Trigger>

            <RadixTooltip.Portal>
              <RadixTooltip.Content {...tooltipContentProps}>
                {content}
                <RadixTooltip.Arrow fill="#26303e" />
              </RadixTooltip.Content>
            </RadixTooltip.Portal>
          </RadixTooltip.Root>
        </RadixTooltip.Provider>
      </div>
    </ThemeProvider>
  );
};
