export * from "./accounts.repository";

import { useCheckPhoneNumber } from "./_hooks/use-check-phone-number";
import { useFetchAllBusinesses } from "./_hooks/use-fetch-all-businesses";
import { useSendCode } from "./_hooks/use-send-code";
import { useSendEmailOtp } from "./_hooks/use-send-email-otp";
import { useSwitchBusiness } from "./_hooks/use-switch-business";
import { useValidateEmail } from "./_hooks/use-validate-email";
import { useVerifyCode } from "./_hooks/use-verify-code";

export const accountsHooks = {
  useCheckPhoneNumber,
  useSendCode,
  useSendEmailOtp,
  useValidateEmail,
  useVerifyCode,
  useFetchAllBusinesses,
  useSwitchBusiness,
};
