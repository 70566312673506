import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import type { AppDispatch } from "store";

import { updateErrorMessage } from "features/Verification/verificationSlice";

import { accountsRepository } from "repositories/accounts/accounts.repository";
import { ACCOUNTS_QUERY_KEYS } from "repositories/accounts/keys";

export const useSendEmailOtp = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useMutation({
    mutationKey: ACCOUNTS_QUERY_KEYS.SEND_EMAIL_OTP,
    mutationFn: async (email: string) => {
      const response = await accountsRepository.sendEmailOtp(email);

      if (!response.success && "error" in response) {
        accountsRepository.sentryLogError({
          error: response.error.message,
          feature: "[Accounts][SendEmailOtp]",
          functionName: "sendEmailOtp",
          data: email,
        });

        dispatch(
          updateErrorMessage({
            errorMessage: {
              otp: JSON.stringify(response.error.message),
            },
          }),
        );
      }

      return response;
    },
  });
};
