import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from "@radix-ui/react-dropdown-menu";
import EmptyStateIllustration from "assets/illustrations/generic-card-empty-state.svg";
import EmptyState from "components/EmptyState";
import Status from "components/Status";
import Toast from "components/ui/Toast";
import routes from "constants/routes";
import { copyText } from "pages/Payment/Pay/helpers";
import toast from "react-hot-toast";

interface ISubscriptionListProps {
  subscriptions: any;
  currentTab: string;
  onDisable: any;
  hasBasic: boolean;
}

const SubscriptionList: React.FC<ISubscriptionListProps> = ({
  subscriptions,
  currentTab,
  onDisable,
  hasBasic,
}) => {
  const isEmptySubscriptions = subscriptions.length == 0;

  const actions = (subscription: any) => {
    const { active, paymentLink } = subscription;
    const { url } = paymentLink;

    const handleCopyLink = () => {
      copyText(url);
      toast.success("Link copied!", {
        duration: 2000,
        style: { fontSize: "16px" },
      });
    };

    if (active) {
      return (
        <DropdownMenu>
          <DropdownMenuTrigger
            className="actions__link__item material-icons-round"
            style={{ backgroundColor: "unset", border: "unset" }}
          >
            more_horiz
          </DropdownMenuTrigger>

          <DropdownMenuPortal>
            <DropdownMenuContent className="actions--subscription-menu z-overall">
              {!hasBasic && (
                <>
                  <DropdownMenuItem className="actions-menu__link" asChild>
                    <a
                      href={url}
                      target="_blank"
                      className="actions-menu__item"
                      rel="noreferrer"
                    >
                      Preview payment link
                    </a>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="actions-menu__link"
                    onClick={handleCopyLink}
                  >
                    <li className="actions-menu__item">Copy payment link</li>
                  </DropdownMenuItem>
                </>
              )}
              <DropdownMenuItem
                className="actions-menu__link"
                onClick={() => onDisable(subscription.paymentLink.identifier)}
              >
                <li className="actions-menu__item text-red-500">
                  Disable recurring payment
                </li>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenuPortal>
        </DropdownMenu>
      );
    }

    return null;
  };

  const handleEmptyState = () => {
    let title, info;
    if (currentTab == "active") {
      title = "No recurring payments yet";
      info = "Create a recurring payment to get started!";
    } else if (currentTab == "inactive") {
      title = "No inactive recurring payments yet";
      info = "Your disabled recurring payments will appear here";
    }

    return (
      <div className="mx-20 rounded-16 bg-white p-48 xl:mx-32 xl:p-128 landscape:lg:mx-0">
        <EmptyState
          imgSrc={EmptyStateIllustration}
          imgAlt="List of subscriptions illustration"
          title={title}
          info={info}
        />
      </div>
    );
  };

  const handleShowSubscription = (identifier: string) => {
    window.location.href = routes.MANAGE.SUBSCRIPTIONS.SHOW(identifier);
  };

  return (
    <>
      {isEmptySubscriptions && handleEmptyState()}
      {!isEmptySubscriptions && (
        <>
          <div className="table-container table-container--tabs">
            <div className="table-container__header">
              <div className="table-container__header__row">
                <p className="table-container__heading">Title</p>
                <p className="table-container__heading">Total subscribers</p>
                <p className="table-container__heading">Amount</p>
                <p className="table-container__heading">Status</p>
                <p className="table-container__heading">End date</p>
                <p className="table-container__heading"></p>
              </div>
            </div>
            <div className="table-container__body">
              {subscriptions.map((subscription: any) => (
                <div key={subscription.id} className="table-container__row">
                  <div
                    className="table-container__data-group"
                    onClick={() =>
                      handleShowSubscription(subscription.identifier)
                    }
                  >
                    <div className="table-container__data">
                      <div>
                        <p className="font-medium text-14 xl:block xl:font-regular">
                          {subscription.title}
                        </p>
                        <p className="mt-4 text-12 text-black-300 xl:hidden">
                          {subscription.totalSubscribers}{" "}
                          <span className="xl:hidden">subscribers</span>
                        </p>
                      </div>
                    </div>
                    <div className="table-container__data xs:hidden xl:table-cell">
                      <div>
                        <p className="text-14">
                          {subscription.totalSubscribers}
                        </p>
                      </div>
                    </div>
                    <div className="table-container__data">
                      <div>
                        <p className="text-right font-medium text-14 xl:text-left xl:font-regular">
                          {subscription.amountFormatted}
                        </p>
                        <p className="mt-4 text-right text-12 text-black-300 xl:hidden">
                          {subscription.endDateFormatted.includes("payment")
                            ? `After ${subscription.endDateFormatted}`
                            : subscription.endDateFormatted}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="table-container__data-group">
                    <div
                      className="table-container__data"
                      onClick={() =>
                        handleShowSubscription(subscription.identifier)
                      }
                    >
                      <div>
                        <Status
                          color={subscription.active ? "green" : "gray"}
                          label={subscription.active ? "Active" : "Inactive"}
                        />
                      </div>
                    </div>

                    <div
                      className="table-container__data"
                      onClick={() =>
                        handleShowSubscription(subscription.identifier)
                      }
                    >
                      <div>
                        <p className="hidden xl:block xl:text-14 xl:text-black-500">
                          {subscription.endDateFormatted.includes("payment")
                            ? `After ${subscription.endDateFormatted}`
                            : subscription.endDateFormatted}
                        </p>
                      </div>
                    </div>

                    <div className="table-container__data">
                      <div>
                        <div className="actions text-right">
                          {actions(subscription)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <Toast />
        </>
      )}
    </>
  );
};

export default SubscriptionList;
