import { Repository } from "../_base/repository";

import type { Settlement } from "components/Settlements/Details/_types/settlement-details";
import type { ApiResponse } from "repositories/_base/repository.types";

class SettlementsRepository extends Repository {
  constructor() {
    super({
      gateway: "/settlements",
    });
  }

  public async exportAll(id: string) {
    return this._http
      .post<Blob>(`${id}/export`, null, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(this.handleResponseWithoutData)
      .catch(this.handleError);
  }

  public async getSettlement(id: string) {
    return this._http
      .get<ApiResponse<Settlement>>(id)
      .then(this.handleResponse)
      .catch(this.handleError);
  }
}

export const settlementsRepository = new SettlementsRepository();
