import { type FunctionComponent, useMemo } from "react";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import Card from "components/ui/Card";
import { PageHeader } from "./PageHeader";

import { useGlobalConfig } from "hooks/useGetGlobalConfig";

import type IRailsContext from "interfaces/IRailsContext";
import { GlobalConfigProvider } from "providers/GlobalConfigProvider";

import { colorsV2 } from "constants/colors";
import routes from "constants/routes";
import { Invoice } from "design_system/Icons";
import { isIOS } from "pages/Payment/Pay/helpers";
import { getMerchantInfoFromLocalStorage } from "utils/getMerchantInfoFromLocalStorage";

interface IProps {
  railsContext: IRailsContext;
}

const SettingsContainer: FunctionComponent<IProps> = (props: IProps) => {
  const { currentMerchant } = getMerchantInfoFromLocalStorage();
  const { globalConfig, loadingGlobalConfigs } = useGlobalConfig();

  const memoizedGlobalConfig = useMemo(
    () => globalConfig,
    [JSON.stringify(globalConfig)],
  );

  const activePlan = memoizedGlobalConfig?.activePlan;

  if (loadingGlobalConfigs || !activePlan) {
    return <LoaderBusiness />;
  }

  return (
    <GlobalConfigProvider value={{ globalConfig: memoizedGlobalConfig }}>
      <PageHeader />

      <div className="account-settings">
        <div className="account-settings__general">
          {/* PERSONAL DETAILS SETTINGS */}
          <a href={routes.MANAGE.ACCOUNT_SETTINGS.PERSONAL()}>
            <Card hasBorder classes="space-y-24">
              <span className="material-icons-round text-24 text-blue-500">
                account_circle
              </span>
              <div className="space-y-8">
                <h3>Personal details</h3>
                <p className="text-16 text-black-300">
                  Your personal and contact information
                </p>
              </div>
            </Card>
          </a>

          {/* BUSINESS DETAILS SETTINGS */}
          <a href={routes.MANAGE.ACCOUNT_SETTINGS.BUSINESS.INDEX()}>
            <Card hasBorder classes="space-y-24">
              <span className="material-icons-round text-24 text-blue-500">
                business_center
              </span>
              <div className="space-y-8">
                <h3>Business details</h3>
                <p className="text-16 text-black-300">
                  More details about your business
                </p>
              </div>
            </Card>
          </a>

          {/* BANK ACCOUNT SETTINGS */}
          {currentMerchant?.signedUpFor !== "cards_only" && (
            <a href={routes.MANAGE.ACCOUNT_SETTINGS.BANK.INDEX()}>
              <Card hasBorder classes="space-y-24">
                <span className="material-icons-round text-24 text-blue-500">
                  account_balance
                </span>
                <div className="space-y-8">
                  <h3>Bank account</h3>
                  <p className="text-16 text-black-300">
                    Your active bank account where your settlements are sent to
                  </p>
                </div>
              </Card>
            </a>
          )}

          {/* TEAM SETTINGS */}
          <a href={routes.MANAGE.ACCOUNT_SETTINGS.TEAM.INDEX()}>
            <Card hasBorder classes="space-y-24">
              <span className="material-icons-round text-24 text-blue-500">
                supervised_user_circle
              </span>
              <div className="space-y-8">
                <h3>Team</h3>
                <p className="text-16 text-black-300">
                  Manage your team&apos;s roles and add new users to your team
                </p>
              </div>
            </Card>
          </a>

          {/* PLAN SETTINGS */}
          {!isIOS(props.railsContext.sessionContext) && (
            <a href={routes.MANAGE.ACCOUNT_SETTINGS.PLAN()}>
              <Card hasBorder classes="space-y-24">
                <span className="material-icons-round text-24 text-blue-500">
                  card_membership
                </span>
                <div className="space-y-8">
                  <h3>Plan</h3>
                  <p className="text-16 text-black-300">
                    Manage your plan and settlement period
                  </p>
                </div>
              </Card>
            </a>
          )}

          {/* BRANDING SETTINGS */}
          {currentMerchant?.signedUpFor !== "cards_only" && (
            <>
              {!isIOS(props.railsContext.sessionContext) && (
                <a href={routes.MANAGE.ACCOUNT_SETTINGS.BRANDING()}>
                  <Card hasBorder classes="space-y-24">
                    <span className="material-icons-round text-24 text-blue-500">
                      brush
                    </span>
                    <div className="space-y-8">
                      <h3>Branding</h3>
                      <p className="text-16 text-black-300">
                        Add your business branding to the payment link that your
                        customers get
                      </p>
                    </div>
                  </Card>
                </a>
              )}
            </>
          )}

          {/* DEVELOPERS SECTION */}
          <a href={routes.MANAGE.DEVELOPER.INDEX()}>
            <Card hasBorder classes="space-y-24">
              <span className="material-icons-round text-24 text-blue-500">
                code
              </span>
              <div className="space-y-8">
                <h3>Developer</h3>
                <p className="text-16 text-black-300">
                  View your API key, our API docs, extensions and more
                </p>
              </div>
            </Card>
          </a>

          {/* EXPENSES SECTION */}
          <a href={routes.MANAGE.ACCOUNT_SETTINGS.EXPENSES.INDEX()}>
            <Card hasBorder classes="space-y-24">
              <Invoice size="24" fill={colorsV2.primary[1]} />
              <div className="space-y-8">
                <h3>Expenses</h3>
                <p className="text-16 text-black-300">
                  Manage your expenses configurations
                </p>
              </div>
            </Card>
          </a>
        </div>
      </div>
    </GlobalConfigProvider>
  );
};

export default SettingsContainer;
