import * as Tooltip from "@radix-ui/react-tooltip";

interface ProgressBarTooltipProps {
  balance: number;
  creditLimit: number;
  children: React.ReactNode;
}

export const formatCurrency = (amount: number): string => {
  if (amount >= 1000000) {
    return `AED ${(amount / 1000000).toFixed(1)}M`;
  } else if (amount >= 1000) {
    return `AED ${(amount / 1000).toFixed(1)}k`;
  } else {
    return `AED ${amount.toFixed(2)}`;
  }
};

export const ProgressBarTooltip: React.FC<ProgressBarTooltipProps> = ({
  balance,
  creditLimit,
  children,
}) => {
  const available = balance;
  const consumed = creditLimit - balance;

  return (
    <Tooltip.Provider>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content className="TooltipContent" sideOffset={5}>
            <div>Available: {formatCurrency(available)}</div>
            <div>Consumed: {formatCurrency(consumed)}</div>
            <Tooltip.Arrow className="TooltipArrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
