import { Link, useLocation } from "react-router-dom";

import { routes } from "constants/routes";
import { Tabs } from "design_system/Tab";
import { useScreenDimension } from "hooks/useScreenDimension";

type TabRoute = {
  label: string;
  path: string;
};

const TAB_ROUTES: readonly TabRoute[] = [
  {
    label: "Revenue",
    path: routes.MANAGE.INSIGHTS.REVENUE(),
  },
  {
    label: "Spend",
    path: routes.MANAGE.INSIGHTS.SPEND(),
  },
  {
    label: "Customer metrics",
    path: routes.MANAGE.INSIGHTS.CUSTOMER_METRICS(),
  },
] as const;

export const NavigationTabs = () => {
  const location = useLocation();
  const { isMobile } = useScreenDimension();

  // Helper function to get the current tab value based on pathname
  function getCurrentTab() {
    const path = location.pathname;
    return path;
  }

  return (
    <Tabs
      size={isMobile ? "md" : "lg"}
      value={getCurrentTab()}
      aria-label="insights navigation"
    >
      {TAB_ROUTES.map(({ label, path }) => (
        <Tabs.Tab
          label={label}
          value={path}
          component={Link}
          to={path}
          key={path}
        />
      ))}
    </Tabs>
  );
};
