import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useFormContext } from "react-hook-form";

import { endpoints } from "constants/apiEndpoints";

export const useGetAccountHolderNames = () => {
  const url = endpoints.MANAGE.API.SETTINGS.BANK.ACCOUNT_HOLDER_NAMES;

  const getNames: QueryObserverResult<any> = useQuery({
    queryKey: ["getAccountHolderNames"],
    queryFn: () => axios.get(url).then((res) => res.data.data),
  });

  return {
    getNames,
  };
};

export const useUpdateIbanForm = () => {
  const formContext = useFormContext();

  const { watch, setValue } = formContext;
  const { accountHolderName } = watch();

  const setAccountHolderName = (value: string) => {
    if (value !== accountHolderName) {
      setValue("accountHolderName", value, { shouldDirty: true });
    }
  };

  return {
    fields: watch(),
    formControl: formContext,
    setAccountHolderName,
  };
};
