import { Step, Stepper } from "@mui/material";
import { ThemeProvider, useTheme } from "@mui/material/styles";

import { customTheme } from "../shared/theme/theme";

export interface ProgressIndicatorProps {
  activeStep: number;
  steps: number;
}

export const ProgressIndicator = ({
  activeStep,
  steps,
}: ProgressIndicatorProps) => {
  const outerTheme = useTheme();

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <Stepper activeStep={activeStep}>
        {Array.from({ length: steps }).map((_, index) => (
          <Step key={index} />
        ))}
      </Stepper>
    </ThemeProvider>
  );
};
