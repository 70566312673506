import { AnimatePresence, motion } from "framer-motion";
import { type ReactNode, useContext } from "react";
import { createPortal } from "react-dom";

import { cn } from "utils/classNames";
import { IslandContext } from "./IslandContext";

export type IslandProps = {
  children: ReactNode;
  open?: boolean;
  classNames?: {
    container?: string;
    content?: string;
  };
};

const Island = ({
  children,
  open = false,
  classNames = {
    container: "",
    content: "",
  },
}: IslandProps) => {
  const { isIslandOpen } = useContext(IslandContext);
  const isOpen = open || isIslandOpen;

  const islandPortal = (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 0.4, ease: [0.16, 1, 0.3, 1] }}
          style={{ zIndex: 1000 }}
          className={cn(
            "fixed bottom-40 flex h-fit-content w-full justify-center",
            classNames.container,
          )}
        >
          <div
            className={cn(
              "relative rounded-16 border border-border-2 bg-surface-2 px-24 py-12 shadow-lg",
              classNames.content,
            )}
          >
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );

  return createPortal(islandPortal, document.body);
};

export default Island;

export type LabelProps = {
  children: ReactNode;
  className?: string;
};

Island.Label = ({ children, className = "" }: LabelProps) => (
  <span className={cn("label-large text-text-color-03", className)}>
    {children}
  </span>
);

export { Island };
