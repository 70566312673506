export const SnackVariants = {
  info: "info",
  attention: "attention",
  critical: "critical",
  success: "success",
} as const;

export type SnackVariants = (typeof SnackVariants)[keyof typeof SnackVariants];

export type SnackProps = BaseSnackProps & SnackLeadingElementProps;

export const LeadingElements = {
  emoji: "emoji",
  icon: "icon",
} as const;

export type LeadingElements =
  (typeof LeadingElements)[keyof typeof LeadingElements];

interface BaseSnackProps {
  description?: string;
  duration?: number;
  onActionClick?: () => void;
  title: string;
}

type SnackLeadingElementProps =
  | {
      leadingElement: "emoji";
      emoji: string;
      variant?: never;
    }
  | {
      leadingElement: "icon";
      emoji?: never;
      variant: SnackVariants;
    };
