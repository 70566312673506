import { useContext } from "react";
import { useDispatch } from "react-redux";

import { TableCell } from "components/Table/TableCell";
import { Tag } from "design_system/Tag";

import { SideSheetContext } from "design_system/Sidesheet/SideSheetContext";
import type { TagColors } from "design_system/Tag/typeDefs";
import { onSelectTransaction } from "features/Wallet/WalletV2/reducer/WalletSlice";
import type { WalletTransaction } from "features/Wallet/WalletV2/typeDefs";
import { setPagination } from "reducers/redux/TableReducer/tableReducer";
import {
  activityStatusMappings,
  activityTypeMappings,
} from "../constants/activityMapping";

type TColumnCell = {
  row: {
    original: WalletTransaction;
  };
};
export const useTransactionsTable = () => {
  const dispatch = useDispatch();
  const { openSideSheet } = useContext(SideSheetContext);

  const handleCellClick = (transaction) => {
    return () => {
      dispatch(onSelectTransaction(transaction));
      setTimeout(() => {
        openSideSheet();
      }, 100);
    };
  };

  const COLUMNS = [
    {
      header: "Activity",
      accessorKey: "breakdownType",
      cell: (props: TColumnCell) => {
        const transaction = props.row.original;
        const title = activityTypeMappings[transaction.breakdownType];
        return (
          <TableCell
            mainContent={title}
            secondaryContent={transaction.reference?.id}
            onClick={handleCellClick(transaction)}
          />
        );
      },
    },
    {
      header: "Date",
      accessorKey: "date",
      cell: (props: TColumnCell) => {
        const transaction = props.row.original;

        return (
          <TableCell
            mainContent={transaction.date}
            onClick={handleCellClick(transaction)}
          />
        );
      },
    },
    {
      header: "Amount",
      accessorKey: "amountFormatted",
      meta: {
        alignment: "end",
      },
      cell: (props: TColumnCell) => {
        const transaction = props.row.original;

        return (
          <TableCell
            mainContent={transaction.amountFormatted}
            classNames={{
              container: "w-full",
              mainContent: "text-right",
            }}
            onClick={handleCellClick(transaction)}
          />
        );
      },
    },
    {
      header: "Balance",
      accessorKey: "balanceFormatted",
      meta: {
        alignment: "end",
      },
      cell: (props: TColumnCell) => {
        const transaction = props.row.original;

        return (
          <TableCell
            mainContent={transaction.balanceFormatted}
            classNames={{
              container: "w-full",
              mainContent: "text-right",
            }}
            onClick={handleCellClick(transaction)}
          />
        );
      },
    },
    {
      header: "Status",
      accessorKey: "status",
      cell: (props: TColumnCell) => {
        const transaction = props.row.original;
        const status = activityStatusMappings[transaction.status];
        return (
          <div className="mt-16 ml-16 w-full">
            <div onClick={handleCellClick(transaction)}>
              <Tag
                color={status.color as TagColors}
                label={status.label}
                Icon={status.Icon}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const handleSetPage = (newPageNumber: number) => {
    dispatch(setPagination({ tableName: "wallets", page: newPageNumber }));
  };

  return {
    COLUMNS,
    handleCellClick,
    handleSetPage,
  };
};
