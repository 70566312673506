import { type QueryObserverResult, useQuery } from "@tanstack/react-query";
import type { ISettlementBalanceProps } from "interfaces/overview/IOverview";
import { analyticsRepository } from "repositories/analytics";

export const useGetUpcomingSettlements = (config?: { enabled: boolean }) => {
  const getUpcomingSettlements: QueryObserverResult<ISettlementBalanceProps> =
    useQuery({
      enabled: config?.enabled ?? true,
      queryKey: ["getUpcomingSettlements"],
      queryFn: async () => {
        const response = await analyticsRepository.getUpcomingSettlements();

        if (!response.success && "error" in response) {
          analyticsRepository.sentryLogError({
            error: response.error.message,
            feature: "useGetUpcomingSettlements",
            functionName: "getUpcomingSettlementsQuery",
            data: JSON.stringify(response.error),
          });
        }

        return response.data;
      },
    });

  return {
    getUpcomingSettlements,
  };
};
