import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "utils/classNames";

const buttonVariants = cva(
  cn(
    // Layout
    "inline-flex items-center justify-center",
    "gap-8",
    // Sizing & Shape
    "whitespace-nowrap rounded-full",
    // Ring & Focus
    "ring-offset-background",
    "focus-visible:outline-none focus-visible:ring-2",
    "focus-visible:ring-ring focus-visible:ring-offset-2",

    // Base Disabled States
    "disabled:cursor-not-allowed",
    "disabled:pointer-events-none",
    "aria-disabled:cursor-not-allowed",
    "aria-disabled:pointer-events-none",
    // SVG Styles
    "[&>svg]:pointer-events-none",
    "[&_svg]:size-4",
    "[&_svg]:shrink-0",
    // Transitions
    "transition-colors",
  ),
  {
    variants: {
      variant: {
        default: cn(
          // Base styles
          "bg-primary-1",
          "text-white",
          // Hover state
          "not-disabled:hover:bg-primary-2",
          // Disabled state
          "aria-disabled:bg-surface-3",
          "aria-disabled:text-text-color-disabled",
        ),
        destructive: cn(
          // Base styles
          "bg-destructive",
          "text-destructive-foreground",
          // Hover state
          "not-disabled:hover:bg-destructive/90",
          // Disabled state
          "aria-disabled:opacity-50",
        ),
        outlined: cn(
          // Base styles
          "border border-border-2",
          "bg-surface-1",
          "text-text-color-2",
          // Interactive states
          "not-disabled:hover:bg-surface-2",
          // Disabled states
          "disabled:text-text-color-disabled",
          "aria-disabled:bg-surface-1",
          "aria-disabled:text-text-color-disabled",
        ),
        secondary: cn(
          // Base styles
          "bg-secondary",
          "text-secondary-foreground",

          // Hover state
          "not-disabled:hover:bg-secondary/80",

          // Disabled state
          "aria-disabled:opacity-50",
        ),
        ghost: cn(
          // Hover states
          "not-disabled:hover:bg-accent",
          "not-disabled:hover:text-accent-foreground",

          // Disabled state
          "aria-disabled:opacity-50",
        ),
        link: cn(
          // Base style
          "text-primary",

          // Hover state
          "not-disabled:hover:underline",

          // Disabled state
          "aria-disabled:opacity-50",
        ),
      },
      size: {
        default: "label-medium h-40 px-24 py-12",
        sm: "label-small h-36 px-20 py-9",
        lg: "label-large h-48 px-24 py-12",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    href?: string;
  };

const BaseButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, asChild = false, disabled, href, ...props },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        aria-disabled={disabled}
        {...props}
      />
    );
  },
);
BaseButton.displayName = "Button";

export { BaseButton as Button, buttonVariants };
