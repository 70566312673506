import { useState } from "react";
import { cn } from "utils/classNames";

import { Loader } from "design_system/Loader";

import { colorsV2 } from "constants/colors";
import type { ButtonProps } from "./typeDefs";

export const Button = ({
  applied = false,
  classes,
  emoji,
  highlighted,
  Icon,
  iconOnly,
  iconPosition = "left",
  iconClasses,
  iconFill,
  imgUrl,
  label,
  loading,
  size,
  variant,
  ...props
}: ButtonProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const getDefaultIconFill = () => {
    if (props.disabled) return colorsV2.text.disabled;
    if (variant === "filled" || variant === "critical")
      return colorsV2.text.onprimary;
    if (variant === "outlined") return colorsV2.text[2];
    return undefined;
  };

  return (
    <button
      // @ts-ignore
      type="button"
      className={cn(
        "relative z-10 flex items-center justify-center gap-8 text-nowrap rounded-full border font-medium transition-all duration-200",
        {
          // ICON CONDITIONAL STYLING
          "h-36 w-36": size === "sm" && (iconOnly || loading),
          "h-40 w-40": size === "md" && (iconOnly || loading),
          "h-48 w-48": size === "lg" && (iconOnly || loading),
          "flex-row": iconPosition === "left",
          "flex-row-reverse": iconPosition === "right",

          // VARIANT CONDITIONAL STYLING
          "border-transparent bg-primary-1 text-text-color-onprimary hover:bg-primary-2":
            variant === "filled" && !props.disabled,
          "border-border-2 bg-surface-1 text-text-color-02 hover:border-border-1 hover:bg-surface-2":
            variant === "outlined" && !props.disabled,
          "border-transparent bg-error-1 text-text-color-onprimary hover:bg-error-2":
            variant === "critical" && !props.disabled,
          "border-accent-yellow-01 bg-accent-yellow-03 text-text-color-02 hover:border-accent-yellow-02 hover:bg-accent-yellow-04":
            highlighted && !isFocused,
          "": variant === "custom" && !props.disabled,

          // APPLIED CONDITIONAL STYLING
          "border-border-5 bg-surface-3 ":
            variant === "outlined" && applied && !props.disabled,

          // SIZE CONDITIONAL STYLING
          "label-small h-36 px-16 py-8": size === "sm" && !iconOnly && !loading,
          "label-medium h-40 px-20 py-10":
            size === "md" && !iconOnly && !loading,
          "label-large h-48 px-24 py-12":
            size === "lg" && !iconOnly && !loading,

          // DISABLED CONDITIONAL STYLING
          "cursor-not-allowed border-transparent bg-surface-3 text-text-color-disabled hover:bg-surface-3":
            props.disabled && (variant === "filled" || variant === "critical"),
          "cursor-not-allowed border-border-3 bg-surface-1 fill-text-color-disabled text-text-color-disabled hover:bg-surface-1":
            props.disabled && (variant === "outlined" || variant === "custom"),

          // LOADING CONDITIONAL STYLING
          "pointer-events-none cursor-not-allowed": loading,

          [`${classes}`]: !!classes,
        },
      )}
      onFocus={handleFocus}
      style={{
        color: highlighted && !isFocused && colorsV2.text[2],
        ...props.style,
      }}
      {...props}
    >
      {!loading && (
        <>
          {Icon && !imgUrl && (
            <Icon
              size={
                size === "lg"
                  ? "18"
                  : size === "md"
                    ? "18"
                    : size === "sm" && "12"
              }
              fill={iconFill || getDefaultIconFill()}
              className={cn(iconClasses)}
            />
          )}
          {!Icon && imgUrl && (
            <img
              src={imgUrl}
              alt=""
              className={cn({
                "h-18 w-18": size === "lg" || size === "md",
                "h-12 w-12": size === "sm",
              })}
            />
          )}
          {!Icon && !imgUrl && emoji && (
            <span
              className={cn({
                "label-small": size === "sm",
                "label-medium": size === "md",
                "label-large": size === "lg",
              })}
            >
              {emoji}
            </span>
          )}
          {!iconOnly && <span>{label}</span>}
        </>
      )}
      {loading && (
        <div style={{ transform: "scale(0.6)" }}>
          <Loader
            color={
              variant === "filled" || variant === "critical" ? "white" : "blue"
            }
          />
        </div>
      )}
    </button>
  );
};
