import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "design_system/dropdowns";
import isEmpty from "lodash/isEmpty";
import { useEffect, useMemo, useState } from "react";
import { cn } from "utils/classNames";

// COMPONENTS
import { IconButton } from "design_system/IconButton";
import { Done, UnfoldMore } from "design_system/Icons";
import { useScreenDimension } from "hooks/useScreenDimension";

// OTHERS
import { colorsV2 } from "constants/colors";
import { shrinkString } from "helpers/stringUtils";
import { accountsHooks } from "repositories/accounts";
import { merchantHooks } from "repositories/merchant";

export const SwitchBusiness = () => {
  const { data: merchantInfo } = merchantHooks.useMerchantInfo();

  const { data: allBusinesses } = accountsHooks.useFetchAllBusinesses();
  const { mutateAsync: switchBusiness } = accountsHooks.useSwitchBusiness();

  const { isMobile, isTablet } = useScreenDimension();

  const [currentSignedInBusiness, setCurrentSignedInBusiness] =
    useState<string>("");

  const currentMerchant = merchantInfo?.currentMerchant;

  const businessName = useMemo(() => {
    if (!currentMerchant) return "";
    return isMobile || isTablet
      ? shrinkString(currentMerchant?.name, 2, 20)
      : shrinkString(currentMerchant?.name, 5, 30);
  }, [currentMerchant]);

  const hasNoMultipleAccounts = isEmpty(allBusinesses?.data);

  const onChangeBusiness = async (identifier: string) => {
    const response = await switchBusiness(identifier);
    if (response.success) {
      window.location.href = response.data.url;
    }
  };

  useEffect(() => {
    if (!hasNoMultipleAccounts) {
      allBusinesses?.data.map((item) => {
        if (currentMerchant?.identifier === item.merchant.identifier) {
          setCurrentSignedInBusiness(item.identifier);
        }
      });
    }
  }, [hasNoMultipleAccounts, currentMerchant]);

  return (
    <div>
      {hasNoMultipleAccounts ? (
        <span className="label-large">{businessName}</span>
      ) : (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <button
              type="button"
              className="flex items-center gap-8 rounded-8 p-4 hover:bg-surface-2 focus:outline-none"
            >
              <span className="label-large">{businessName}</span>
              <IconButton size="xs" Icon={UnfoldMore} />
            </button>
          </DropdownMenuTrigger>

          <DropdownMenuContent align="start" sideOffset={10}>
            <DropdownMenuRadioGroup
              value={currentSignedInBusiness}
              onValueChange={onChangeBusiness}
            >
              {allBusinesses?.data.map((account) => (
                <DropdownMenuRadioItem
                  key={account.identifier}
                  value={account.identifier}
                  className={cn({
                    "bg-surface-2":
                      currentSignedInBusiness === account.identifier,
                  })}
                >
                  <span className="body-medium">{account.merchant.name}</span>
                  {currentSignedInBusiness === account.identifier && (
                    <Done size="18" fill={colorsV2.accent.green[1]} />
                  )}
                </DropdownMenuRadioItem>
              ))}
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
};
