import Autocomplete, {
  type AutocompleteProps,
} from "@mui/material/Autocomplete";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";

import { Close } from "design_system/Icons";
import { CustomPaper, CustomPopper } from "./components";
import { CustomListbox } from "./components/CustomListbox";

import { useRenderGroup } from "./hooks/useRenderGroup";
import { useRenderInput } from "./hooks/useRenderInput";
import { useRenderOptions } from "./hooks/useRenderOptions";
import { useSearchField } from "./hooks/useSearchField";

import { colorsV2 } from "constants/colors";
import { customTheme } from "design_system/shared/theme/theme";
import type { ITextInputProps } from "../../../design_system/Inputs/TextInput/TextInput";
import { autocompleteStyleOverrides } from "./constants/styleOverrides";

export interface SearchInputProps {
  dropdownProps?: AutocompleteProps<any, true, true, true>;
  textInputProps?: ITextInputProps;
}

export const GlobalSearch = ({
  dropdownProps,
  textInputProps,
}: SearchInputProps) => {
  /* ---------------------------------
   *  States & Ref
   * ---------------------------------
   */
  const [isOpen, setIsOpen] = useState(false);
  /* ---------------------------------
   *  Hooks
   * ---------------------------------
   */
  const {
    searchText,
    getGlobalSearchResults: { data, isLoading },
    handleTextChange,
  } = useSearchField();

  const { getOptionLabel, renderOption, options } = useRenderOptions(
    data,
    searchText,
  );

  const { getGroupLabel, renderGroup } = useRenderGroup({
    lastGroupLabel: options[options.length - 1].sectionName,
  });

  const { renderInput } = useRenderInput({
    inputProps: textInputProps,
    searchText: searchText,
    isLoading: isLoading,
    onBlur: () => setIsOpen(false),
    onClose: () => {
      setIsOpen(false);
      handleTextChange("");
    },
  });

  const outerTheme = useTheme();

  useEffect(() => {
    const document = window.document.getElementsByTagName("body")[0];
    if (document) {
      document.style.overflowY = isOpen ? "hidden" : "auto";
    }

    return () => {
      if (document) {
        document.style.overflowY = "auto";
      }
    };
  }, [isOpen]);

  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <Autocomplete
        autoHighlight
        clearIcon={<Close size="12" fill={colorsV2.text[3]} />}
        freeSolo
        inputValue={searchText}
        noOptionsText="No options"
        open={isOpen}
        options={options}
        popupIcon={null}
        getOptionLabel={getOptionLabel}
        groupBy={getGroupLabel}
        ListboxComponent={CustomListbox}
        onClose={() => setIsOpen(false)} // Triggered on escape and blur
        onInputChange={(_, value) => handleTextChange(value)}
        onOpen={() => setIsOpen(true)}
        PaperComponent={CustomPaper}
        PopperComponent={(props) => CustomPopper(props, isOpen)}
        renderGroup={renderGroup}
        renderOption={renderOption}
        renderInput={renderInput}
        sx={autocompleteStyleOverrides(isOpen)}
        ChipProps={{
          deleteIcon: (
            <div className="p-6">
              <Close size="12" />
            </div>
          ),
        }}
        {...dropdownProps}
      />
    </ThemeProvider>
  );
};
