import { useQuery } from "@tanstack/react-query";

import { analyticsRepository } from "repositories/analytics/analytics.repository";

import { ANALYTICS_QUERY_KEYS } from "repositories/analytics/keys";
import type { GetAnalyticsParams } from "repositories/analytics/types";

export const useGetAnalyticsData = (params: GetAnalyticsParams) => {
  return useQuery({
    queryKey: ANALYTICS_QUERY_KEYS.GET_ANALYTICS,
    queryFn: async () => {
      const response = await analyticsRepository.getAnalytics(params);

      if (!response.success && "error" in response) {
        analyticsRepository.sentryLogError({
          feature: "Insights",
          functionName: "useGetAnalyticsData",
          data: JSON.stringify(response),
          error: response.error.message,
        });
      }

      return response;
    },
  });
};
