import isEmpty from "lodash/isEmpty";

import { Banner } from "design_system/Banner";
import { Info } from "design_system/Icons";
import { MasterHeader } from "design_system/MasterHeader";
import { Tag } from "design_system/Tag";

import routes from "constants/routes";
import type { TagColors } from "design_system/Tag/typeDefs";
import type { Settlement } from "./_types/settlement-details";

const colorsMapping: { [key: string]: TagColors } = {
  held_by_bank: "blue",
  settled: "green",
  rejected_by_bank: "red",
  pending: "blue",
};

export const SettlementDetailsHeader = (settlements: Settlement) => {
  const { state, stateHumanize, identifier, settledAt } = settlements;
  const isRejectByBank = settlements.state === "rejected_by_bank";

  const handleGoBackToSettlements = () => {
    window.location.href = routes.MANAGE.SETTLEMENTS.INDEX();
  };

  return (
    <MasterHeader onBack={handleGoBackToSettlements}>
      <MasterHeader.Label>Settlement</MasterHeader.Label>
      <MasterHeader.Title>{identifier}</MasterHeader.Title>
      <MasterHeader.Tag>
        <Tag size="md" color={colorsMapping[state]} label={stateHumanize} />
      </MasterHeader.Tag>
      {!isEmpty(settledAt) && (
        <MasterHeader.Description>{`Settled on ${settledAt}`}</MasterHeader.Description>
      )}

      {isRejectByBank && (
        <MasterHeader.Banner>
          <Banner
            id="settlement-rejected-by-bank"
            variant="attention"
            leadingElement="icon"
            Icon={Info}
            title="Settlement rejected by your bank"
            description="If your IBAN has changed or is incorrect, please update your IBAN from Settings and contact us to re-initiate the transfer."
          />
        </MasterHeader.Banner>
      )}
    </MasterHeader>
  );
};
