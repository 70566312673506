import { z } from "zod";

/**
 * Used for ex. updating bank account details
 */
export const updateBankAccountSchema = z
  .object({
    iban: z.string().min(1, { message: "IBAN is required" }),
    accountHolderName: z
      .string()
      .min(1, { message: "Account holder name is required" }),
    otp: z
      .string()
      .min(6, { message: "Code is invalid. Please try again." })
      .max(6, {
        message: "Code is invalid. Please try again.",
      }),
  })
  .required()
  .strict();

export type UpdateBankAccount = z.infer<typeof updateBankAccountSchema>;
