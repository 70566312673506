import * as RadixDropdown from "@radix-ui/react-dropdown-menu";
import { cn } from "utils/classNames";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import { shrinkString } from "helpers/stringUtils";

import { colorsV2 } from "constants/colors";
import { Store } from "design_system/Icons";
import { isEmpty } from "lodash";
import { accountsHooks } from "repositories/accounts";
import { merchantHooks } from "repositories/merchant";
import type { TSwitchBusiness } from "./shared/types";

export const SwitchBusiness = ({ compact, noDropdown }: TSwitchBusiness) => {
  const { data: merchantInfo } = merchantHooks.useMerchantInfo();

  const { data: allBusinesses, isLoading } =
    accountsHooks.useFetchAllBusinesses();

  const { mutateAsync: switchBusiness } = accountsHooks.useSwitchBusiness();

  const currentMerchant = merchantInfo?.currentMerchant;

  const onChangeBusiness = async (identifier: string) => {
    const response = await switchBusiness(identifier);
    if (response.success) {
      window.location.href = response.data.url;
    }
  };

  if (noDropdown) {
    return (
      <div className="md:hidden">
        {isLoading ? (
          <div className="scale-75 transform">
            <LoaderBusiness noWrapper />
          </div>
        ) : (
          !isEmpty(allBusinesses?.data) && (
            <>
              <RadixDropdown.Separator className="h-1 w-full bg-black-50" />
              <RadixDropdown.Label className="mt-16 mb-4 px-12 font-regular text-12 text-black-300">
                Your business accounts
              </RadixDropdown.Label>
              <RadixDropdown.RadioGroup
                value={currentMerchant?.identifier}
                onValueChange={onChangeBusiness}
              >
                {allBusinesses?.data?.map((item) => (
                  <RadixDropdown.RadioItem
                    key={item.identifier}
                    className="flex cursor-pointer items-center justify-between gap-12 rounded-8 p-12 outline-none hover:bg-black-25 focus:bg-black-25"
                    value={item.identifier}
                  >
                    <div className="flex items-center gap-12">
                      <Store size="18" fill={colorsV2.text[2]} />
                      <span className="text-14">{item.merchant.name}</span>
                    </div>
                    <RadixDropdown.ItemIndicator className="material-icons-round text-18 text-green-400">
                      check
                    </RadixDropdown.ItemIndicator>
                  </RadixDropdown.RadioItem>
                ))}
              </RadixDropdown.RadioGroup>
            </>
          )
        )}
      </div>
    );
  }

  return (
    <>
      {!isEmpty(allBusinesses?.data) && (
        <RadixDropdown.Root>
          <RadixDropdown.Trigger>
            <button
              type="button"
              className={cn(
                "flex items-center justify-between rounded-full border border-black-50 bg-white outline-none hover:border-black-200 focus:border-black-200",
                {
                  "h-40 w-40 text-center": compact,
                  "px-16 py-8": !compact,
                },
              )}
            >
              {!compact ? (
                <>
                  <div className="flex items-center gap-8">
                    <span className="material-icons-round p-4 text-16 text-black-200">
                      store
                    </span>
                    <span className="font-medium text-12">
                      {shrinkString(currentMerchant?.name || "", 3, 20)}
                    </span>
                  </div>
                  <span className="material-icons-round text-18 text-black-200">
                    keyboard_arrow_down
                  </span>
                </>
              ) : (
                <div className="w-full font-medium text-12 uppercase">
                  {currentMerchant?.name.charAt(0)}
                </div>
              )}
            </button>
          </RadixDropdown.Trigger>

          <RadixDropdown.Portal>
            <RadixDropdown.Content
              className="z-overall rounded-16 bg-white p-8 shadow-lg"
              sideOffset={5}
              align="end"
              side="bottom"
              data-state="open"
            >
              {allBusinesses?.data?.map((item) => (
                <RadixDropdown.Item key={item.identifier} asChild>
                  <button
                    type="button"
                    onClick={() => onChangeBusiness(item.identifier)}
                    className="flex w-full cursor-pointer items-center justify-between gap-12 rounded-8 p-8 outline-none hover:bg-black-25 focus:bg-black-25"
                  >
                    <span className="font-medium text-12">
                      {item.merchant.name}
                    </span>
                    {item.identifier === currentMerchant?.identifier && (
                      <span className="material-icons-round text-18 text-green-400">
                        check
                      </span>
                    )}
                  </button>
                </RadixDropdown.Item>
              ))}
            </RadixDropdown.Content>
          </RadixDropdown.Portal>
        </RadixDropdown.Root>
      )}
    </>
  );
};
