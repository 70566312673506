import { groupBy, isEmpty, sumBy } from "lodash";

import Button from "components/Button/Button";
import { convertToLocaleStringHelper } from "helpers/currency";
import type {
  BalanceTransaction,
  Revenue,
  Settlement,
} from "./_types/settlement-details";
import { BalanceTransactionRow, PaymentRow } from "./component/index";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import { snack } from "design_system/Snackbar";
import { SentryLoggingService } from "init/SentryLoggingService";
import { useParams } from "react-router-dom";
import { settlementsHooks } from "repositories/settlements";
import { useGetSettlement } from "repositories/settlements/_hooks/use-get-settlement";
import {
  SettlementDetailsHeader,
  balancetTransactionPluralMapping,
  balancetTransactionSingularMapping,
} from "./index";

export type ContentProps = {
  data: Settlement;
};
export const Content = ({ data }: ContentProps) => {
  const { id } = useParams();
  const { mutateAsync: exportSettlements } =
    settlementsHooks.useExportSettlements();

  const {
    revenues,
    amountWithCurrency,
    balanceTransactions,
    totalRevenueFeeWithCurrency,
    totalRevenueChargeAmountWithCurrency,
  } = data;
  const paymentDisplayText = revenues.length > 1 ? "Payments" : "Payment";
  const feeDisplayText =
    revenues.length > 1 ? "Transaction fees" : "Transaction fee";
  const balanceTransactionGroupByKind = groupBy(balanceTransactions, "kind");

  const DisplayBalanceTransaction = ({ kind }: { kind: string }) => {
    if (isEmpty(kind)) {
      return <></>;
    }

    const totalAmount = sumBy(
      Object.values(balanceTransactionGroupByKind[kind]),
      "amountWithoutCurrency",
    );

    if (kind === "vat") {
      return (
        <li className="settlement-details__summary__item">
          <p className="settlement-details__summary__item__label">
            {balancetTransactionSingularMapping[kind]}
          </p>
          <p className="settlement-details__summary__item__description">
            AED {convertToLocaleStringHelper(totalAmount)}
          </p>
        </li>
      );
    }

    return (
      <li className="settlement-details__summary__item">
        <p className="settlement-details__summary__item__label">
          {balancetTransactionPluralMapping[kind]}
        </p>
        <p className="settlement-details__summary__item__description">
          AED {convertToLocaleStringHelper(totalAmount)}
        </p>
      </li>
    );
  };

  const handleDownloadCsv = async () => {
    try {
      const response = await exportSettlements(id);

      if (!response.success) {
        snack({
          title: "Error while downloading CSV",
          description: "Please try again later",
          variant: "critical",
          leadingElement: "icon",
        });
        return;
      }

      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", `settlement-${id}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error("Download error:", error);

      SentryLoggingService.captureException(error, {
        feature: "settlements",
        function: "downloadCsv",
        file: "SettlementDetails.tsx",
        data: JSON.stringify(error),
      });

      snack({
        title: "Error while downloading CSV",
        description: "Please try again later",
        variant: "critical",
        leadingElement: "icon",
      });
    }
  };

  return (
    <>
      <SettlementDetailsHeader {...data} />
      <section className="settlement-details">
        {/* SUMMARY */}
        <div className="settlement-details__summary">
          <div className="settlement-details__summary__heading">
            <h3>Summary</h3>
          </div>

          <ul className="settlement-details__summary__list">
            {!isEmpty(revenues) && (
              <>
                {/* PAYMENTS */}
                <li className="settlement-details__summary__item">
                  <p className="settlement-details__summary__item__label">
                    {revenues.length} {paymentDisplayText}
                  </p>
                  <p className="settlement-details__summary__item__description">
                    {totalRevenueChargeAmountWithCurrency}
                  </p>
                </li>

                {/* FEES */}
                <li className="settlement-details__summary__item">
                  <p className="settlement-details__summary__item__label">
                    {feeDisplayText}
                  </p>
                  <p className="settlement-details__summary__item__description">
                    {totalRevenueFeeWithCurrency}
                  </p>
                </li>
              </>
            )}

            {/* BALANCE TRANSACTIONS */}
            {Object.keys(balanceTransactionGroupByKind).map((kind: string) => (
              <DisplayBalanceTransaction kind={kind} />
            ))}

            {/* TOTAL */}
            <li className="settlement-details__summary__item">
              <p className="settlement-details__summary__item__label">Total</p>
              <p className="settlement-details__summary__item__description">
                {amountWithCurrency}
              </p>
            </li>
          </ul>
        </div>

        {/* PAYMENTS LIST MOBILE:CARD / DESKTOP:TABLE */}
        <div className="space-y-12 xl:space-y-24 xl:rounded-16 xl:border xl:border-black-50 xl:bg-white xl:p-32">
          <div className="flex items-center justify-between">
            <h3>Transactions</h3>
            <Button
              color="secondary"
              size="sm"
              isIcon
              iconFont="download"
              onClick={handleDownloadCsv}
            />
          </div>
          <div className="table-container">
            <div className="table-container__header">
              <div className="table-container__header__row">
                <p className="table-container__heading">Reference ID</p>
                <p className="table-container__heading">Type</p>
                <p className="table-container__heading">Date</p>
                <p className="table-container__heading">Customer</p>
                <p className="table-container__heading">Amount</p>
                <p className="table-container__heading">VAT</p>
              </div>
            </div>

            <div className="table-container__body">
              {Object.values(revenues)?.map((i: Revenue) => (
                <PaymentRow {...i} key={i?.identifier} />
              ))}
              {Object.values(balanceTransactions)?.map(
                (i: BalanceTransaction) => (
                  <BalanceTransactionRow {...i} key={i?.identifier} />
                ),
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export const SettlementDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetSettlement(id);

  if (isLoading) {
    return <LoaderBusiness />;
  }

  if (!isLoading && data.success) {
    return <Content data={data.data} />;
  }
};
