import { useMutation } from "@tanstack/react-query";

import { userRepository } from "repositories/user";
import { USER_QUERY_KEYS } from "repositories/user/_hooks/keys";
import type { GenerateOtpRequest } from "repositories/user/types";

export const useGenerateOtp = () => {
  return useMutation({
    mutationKey: [USER_QUERY_KEYS.GENERATE_OTP],
    mutationFn: async (request: GenerateOtpRequest) => {
      const response = await userRepository.generateOtp(request);

      if (!response.success && "error" in response) {
        userRepository.sentryLogError({
          error: response.error.message,
          feature: "useGenerateOtp",
          functionName: "generateOtp",
          data: JSON.stringify(response.error),
        });

        return response;
      }

      return response;
    },
  });
};
