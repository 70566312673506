/**
 * Account item for the dropdown
 */

import { isEmpty } from "lodash";
import type { ReactNode } from "react";

import { cn } from "utils/classNames";

export type AccountItemProps = {
  icon: ReactNode;
  label: string;
  supportingText: string;
};

export function AccountItem({ icon, label, supportingText }: AccountItemProps) {
  return (
    <div
      className={cn("flex items-start gap-8", {
        "items-center": isEmpty(supportingText),
      })}
      data-testid="account-item"
    >
      <div
        className="mt-2 h-24 w-24 overflow-hidden rounded-full"
        data-testid="account-item-icon"
      >
        {icon}
      </div>

      <div className="flex flex-col" data-testid="account-item-content">
        <span className="body-medium text-text-color-01">{label}</span>

        <span className="body-small text-text-color-02">
          {supportingText} available
        </span>
      </div>
    </div>
  );
}
