import { createSearchParams, useLocation } from "react-router-dom";

import { routes } from "constants/routes";

import { SwapHorizontal } from "design_system/Icons";
import { Button } from "design_system/buttons";
import { cn } from "utils/classNames";

export type TransferFundsButtonProps = {
  className?: string;
  fromAccount?: string;
};

export function TransferFundsButton({
  className,
  fromAccount,
}: TransferFundsButtonProps) {
  const { pathname } = useLocation();

  const searchParams = createSearchParams({
    onSuccessUrl: pathname,
    ...(fromAccount ? { fromAccount } : {}),
  }).toString();

  const transferUrl = `${routes.MANAGE.WALLET.TRANSFER()}?${searchParams}`;

  return (
    <Button asChild className={cn(className)}>
      <a href={transferUrl}>
        <SwapHorizontal />
        Transfer
      </a>
    </Button>
  );
}
