import { useQuery } from "@tanstack/react-query";

import { MERCHANT_QUERY_KEYS } from "repositories/merchant/keys";
import { merchantRepository } from "repositories/merchant/merchant.repository";

export const useGetPlanDetails = () => {
  return useQuery({
    queryKey: MERCHANT_QUERY_KEYS.GET_PLAN_DETAILS,
    queryFn: async () => {
      const response = await merchantRepository.getPlanDetails();

      if (!response.success && "error" in response) {
        merchantRepository.sentryLogError({
          error: response.error.message,
          feature: "merchant",
          functionName: "getPlanDetails",
          data: JSON.stringify(response.error),
        });
      }

      return response;
    },
  });
};
