import { useMutation } from "@tanstack/react-query";

import type { Channel } from "components/TwoFactorAuthentication/_shared/schema";
import { snack } from "design_system/Snackbar";

import { accountsRepository } from "repositories/accounts/accounts.repository";
import { ACCOUNTS_QUERY_KEYS } from "repositories/accounts/keys";

export const useSendCode = () => {
  return useMutation({
    mutationKey: ACCOUNTS_QUERY_KEYS.SEND_CODE,
    mutationFn: async ({
      phoneNumber,
      channel,
    }: {
      phoneNumber: string;
      channel: Channel;
    }) => {
      const response = await accountsRepository.sendPhoneNumberOtp(
        phoneNumber,
        channel,
      );

      if (!response.success && "error" in response) {
        snack({
          title: response.error.message,
          leadingElement: "icon",
          variant: "critical",
        });

        accountsRepository.sentryLogError({
          error: response.error.message,
          feature: "accounts",
          functionName: "sendPhoneNumberOtp",
          data: JSON.stringify(response.error),
        });
      }

      return response.data;
    },
  });
};
