import { useReducer } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import SuccessPage from "components/shared/SuccessPage";
import routes from "constants/routes";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

import type IRailsContext from "interfaces/IRailsContext";
import type { SessionContext } from "types/SessionContext";

import LoaderBusiness from "components/Loader/LoaderBusiness";
import { CurrentSessionProvider } from "components/Providers/CurrentSession.provider";
import { useMerchantInfo } from "repositories/merchant/_hooks/use-merchant-info";
import { useGetPlans } from "repositories/plans/useGetPlans";
import choosePlanReducer, { initialState } from "./reducers/choosePlanReducer";

const showSuccessText = () => {
  return (
    <p>
      Your pricing plan is all sorted - we can’t wait to see how you put our
      Mamo Business features to use!
    </p>
  );
};

export type ChoosePlanProps = {
  railsContext: IRailsContext;
};
export const ChoosePlan = ({ railsContext }: ChoosePlanProps) => {
  const { data, isLoading } = useGetPlans();
  const { data: merchantInfo } = useMerchantInfo();
  const sessionContext = railsContext.sessionContext as SessionContext;
  const [state, dispatch] = useReducer(choosePlanReducer, initialState);

  return (
    <CurrentSessionProvider sessionContext={sessionContext}>
      {isLoading ? (
        <LoaderBusiness />
      ) : (
        <main>
          <Router>
            <Routes>
              <Route
                path={routes.MANAGE.PLANS.INDEX()}
                element={
                  <Step1
                    activePlan={data?.activePlan}
                    plans={data?.plans}
                    state={state}
                    dispatch={dispatch}
                  />
                }
              />
              <Route
                path={routes.MANAGE.PLANS.CHOOSE_SETTLEMENT()}
                element={
                  <Step2
                    state={state}
                    dispatch={dispatch}
                    activePlan={data?.activePlan}
                    eligibleForExpeditedSettlements={
                      data?.eligibleForExpeditedSettlements
                    }
                  />
                }
              />
              <Route
                path={routes.MANAGE.PLANS.CONFIRM_CHANGES()}
                element={<Step3 state={state} dispatch={dispatch} />}
              />
              <Route
                path={routes.MANAGE.PLANS.SUCCESS()}
                element={
                  <SuccessPage
                    title={`Thank you for choosing ${state.selectedPlan}!`}
                    subtext={showSuccessText()}
                  />
                }
              />
            </Routes>
          </Router>
        </main>
      )}
    </CurrentSessionProvider>
  );
};
