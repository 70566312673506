import { useState } from "react";

import Toast from "components/ui/Toast";
import { Loader } from "design_system/Loader";
import { Snackbar } from "design_system/Snackbar";
import { Tabs } from "design_system/Tab";
import { CreditActivities } from "./core/components/CreditActivities";
import { CreditCardOverview } from "./core/components/CreditCardOverview";
import { PageHeader } from "./core/components/PageHeader";

import { useScreenDimension } from "hooks/useScreenDimension";
import { useGetWalletsAndCards } from "../../hooks/useGetWalletsAndCards";
import { useGetCreditCardActivities } from "./core/hooks/apis/useGetCreditCardActivities";
import { useGetCreditCardDetail } from "./core/hooks/apis/useGetCreditCardDetails";
import { useGetRepaymentTransactions } from "./core/hooks/apis/useGetRepaymentTransactions";

import { useAppSelector } from "store";
import type { CreditCardTabs } from "./core/typeDefs";

export const CreditCardDetailPage = () => {
  const { isMobile } = useScreenDimension();
  const {
    table: { creditActivities },
  } = useAppSelector((state) => state);

  const { page } = creditActivities;

  const {
    getCreditCardActivities: { isLoading: isLoadingActivities },
  } = useGetCreditCardActivities({ page }); // Runs in the background
  useGetWalletsAndCards(); // Runs in the background

  const { getCreditCardDetail } = useGetCreditCardDetail();
  const { getRepaymentTransactions } = useGetRepaymentTransactions();

  const [currentTab, setCurrentTab] = useState<CreditCardTabs>("Overview");

  const creditCard = getCreditCardDetail.data;

  if (
    (getCreditCardDetail.isLoading || getRepaymentTransactions.isLoading) &&
    !getCreditCardDetail.isError &&
    !getRepaymentTransactions.isError
  ) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader />
      </div>
    );
  }

  if (getCreditCardDetail.isError || getRepaymentTransactions.isError) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="text-center">
          <h2 className="mb-2 font-bold text-xl">Error loading data</h2>
          <p>
            We're sorry, but we couldn't load the page. Please try again later.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <PageHeader availableCredit={creditCard?.balanceFormatted} />
      <Snackbar />
      <Toast />
      <section>
        <Tabs
          value={currentTab}
          onChange={(e, newTab) => setCurrentTab(newTab)}
          size={isMobile ? "md" : "lg"}
        >
          <Tabs.Tab value="Overview" label="Overview" />
          <Tabs.Tab value="Activity" label="Activity" />
        </Tabs>

        <div data-testid="tab-content-area">
          {currentTab === "Overview" && (
            <CreditCardOverview creditCard={creditCard} />
          )}
          {currentTab === "Activity" &&
            (isLoadingActivities ? (
              <div className="flex items-center justify-center">
                <Loader />
              </div>
            ) : (
              <CreditActivities />
            ))}
        </div>
      </section>
    </>
  );
};
