import { toast } from "sonner/dist/index";

import { IconButton } from "design_system/IconButton";
import { CheckCircleFill, Close, ErrorFill } from "design_system/Icons";

import { colorsV2 } from "constants/colors";
import type { SnackProps, SnackVariants } from "./typeDefs";

const handleIconVariant = (variant: SnackVariants) => {
  if (variant === "attention")
    return <ErrorFill size="18" fill={colorsV2.accent.yellow[1]} />;
  if (variant === "success")
    return <CheckCircleFill size="18" fill={colorsV2.accent.green[1]} />;
  if (variant === "critical")
    return <ErrorFill size="18" fill={colorsV2.error[1]} />;
};

export const snack = ({
  description,
  duration = 5000,
  emoji,
  leadingElement,
  onActionClick,
  title,
  variant,
}: SnackProps) => {
  return toast.custom(
    (t) => (
      <div className="flex flex-col gap-8">
        <div className="flex items-start justify-between gap-8">
          <div className="flex items-start gap-8">
            {leadingElement === "icon" ? (
              <div className="py-2">{handleIconVariant(variant)}</div>
            ) : (
              <span className="body-medium">{emoji}</span>
            )}
            <div className="">
              <p className="label-medium">{title}</p>
              <p className="body-medium">{description}</p>
            </div>
          </div>

          <IconButton Icon={Close} onClick={() => toast.dismiss(t)} />
        </div>

        {variant === "attention" && (
          <button
            type="button"
            onClick={onActionClick}
            className="label-medium ml-auto text-primary-1"
          >
            Action
          </button>
        )}
      </div>
    ),
    { duration: duration },
  );
};
