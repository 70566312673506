import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ReactQueryProvider } from "components/Providers/ReactQueryProvider";
import { RevenueInsights } from "features/Insights/RevenueInsights";
import { InsightsLayout } from "features/Insights/layout";
import { CustomerMetricsInsights } from "./CustomerMetricsInsights";
import { SpendInsights } from "./SpendInsights";

export const Insights = () => {
  return (
    <ReactQueryProvider>
      <BrowserRouter>
        <Routes>
          <Route path="manage/insights" element={<InsightsLayout />}>
            <Route index path="revenue" element={<RevenueInsights />} />
            <Route path="spend" element={<SpendInsights />} />
            <Route
              path="customer-metrics"
              element={<CustomerMetricsInsights />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ReactQueryProvider>
  );
};
