import { useContext, useEffect, useState } from "react";

import { Divider } from "components/Divider";
import { Button } from "design_system/Button";
import SideSheet from "design_system/Sidesheet/Sidesheet";
import { ManualBankTransferSidesheet } from "./ManualBankTransferSidesheet";
import { RepaymentsSidesheet } from "./RepaymentsSidesheet";

import { useBankDetails } from "features/Cards/CardTopup/hooks/useBankDetails";
import { useGetTopupBankDetails } from "features/Cards/CardTopup/hooks/useGetTopupBankDetails";
import { useScreenDimension } from "hooks/useScreenDimension";
import { useAppSelector } from "store";
import { useInitiateRepayment } from "../../../../hooks/apis/useInitiateRepayment";

import { SideSheetContext } from "design_system/Sidesheet/SideSheetContext";

import { queryClient } from "config/reactQueryClient";
import { showHideIntercom } from "utils/displayUtils";

export const RepaymentsSidesheetRoot = () => {
  const {
    table: { repayments },
  } = useAppSelector((state) => state);
  const finances: any = queryClient.getQueryData(["getWalletsAndCards"]);

  const { getTopupBankDetails } = useGetTopupBankDetails();
  const { handleCopyAllBankInfo } = useBankDetails(getTopupBankDetails.data);

  const { initiateRepayment } = useInitiateRepayment();
  const { isMobile } = useScreenDimension();

  const [checkedRecords, setCheckedRecords] = useState<any[]>([]);

  const [isManualPaymentConfirmed, setIsManualPaymentConfirmed] =
    useState(false);
  const [showBankTransferScreen, setShowBankTransferScreen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<
    "mamo_balance" | "bank_transfer"
  >("mamo_balance");

  const { recordDetail, selectedRows } = repayments;
  const records = selectedRows.length > 0 ? [...selectedRows] : [recordDetail];

  const totalAmount = checkedRecords.reduce(
    (sum, checkedRecord) => sum + Number.parseFloat(checkedRecord.totalAmount),
    0,
  );

  const aedWallet = finances?.wallets?.find(
    (wallet) => wallet.currency === "AED",
  );

  const isDisabled =
    (records.length > 1 && checkedRecords.length === 0) ||
    (paymentMethod === "mamo_balance" && totalAmount > aedWallet?.totalBalance);

  const { closeSideSheet } = useContext(SideSheetContext);

  const handleCheckboxChange = (record: any, checked: boolean) => {
    setCheckedRecords((prev) =>
      checked
        ? [...prev, record]
        : prev.filter(({ identifier }) => identifier !== record.identifier),
    );
  };

  const handleConfirmation = async () => {
    if (checkedRecords?.length > 0) {
      const identifiers = checkedRecords.flatMap(
        (record) => record.transactionIdentifiers,
      );

      await initiateRepayment.mutateAsync(
        {
          method: paymentMethod,
          identifiers,
        },
        {
          onSuccess: () => {
            closeSideSheet();
          },
        },
      );
    }
  };

  useEffect(() => {
    showHideIntercom("hide");
    return () => showHideIntercom("show");
  }, []);

  useEffect(() => {
    if (selectedRows.length > 0) {
      setCheckedRecords(selectedRows);
    } else {
      setCheckedRecords([recordDetail]);
    }
  }, []);

  return (
    <SideSheet>
      <SideSheet.Navbar>
        <div className="flex justify-end">
          <SideSheet.Close onClose={() => closeSideSheet()} />
        </div>
      </SideSheet.Navbar>

      <SideSheet.Header>
        <div className="flex items-center gap-16">
          <div className="space-y-16 md:space-y-24">
            <p className="header-medium md:display-small">
              {showBankTransferScreen
                ? "Pay via bank transfer"
                : "Make a repayment"}
            </p>
            <p className="body-medium md:body-large text-text-color-02">
              {showBankTransferScreen
                ? "Transfer funds to the following bank details. It will take 1-2 business days to reflect in your card balance. Please include your unique reference ID in the transfer."
                : "Ensure you have available credit for your business expenses by making an early payment."}
            </p>
          </div>
        </div>
      </SideSheet.Header>
      <Divider />
      <SideSheet.Content>
        {showBankTransferScreen ? (
          <ManualBankTransferSidesheet
            data={getTopupBankDetails.data}
            isLoading={getTopupBankDetails.isLoading}
            isManualPaymentConfirmed={isManualPaymentConfirmed}
            onConfirmManualTransfer={() =>
              setIsManualPaymentConfirmed(!isManualPaymentConfirmed)
            }
          />
        ) : (
          <RepaymentsSidesheet
            records={records}
            checkedRecords={checkedRecords}
            paymentMethod={paymentMethod}
            onSelectPaymentMethod={({ target: { value } }) =>
              setPaymentMethod(value)
            }
            onChange={handleCheckboxChange}
          />
        )}
      </SideSheet.Content>

      <SideSheet.Actions>
        {showBankTransferScreen ? (
          <div className="flex justify-between">
            <Button
              label="Copy all"
              size="lg"
              variant="outlined"
              onClick={handleCopyAllBankInfo}
            />
            <Button
              label="Done"
              size="lg"
              variant="filled"
              disabled={!isManualPaymentConfirmed}
              onClick={() => handleConfirmation()}
            />
          </div>
        ) : (
          <div className="flex justify-end">
            <Button
              label={
                paymentMethod === "bank_transfer" ? "Continue" : "Make payment"
              }
              size={isMobile ? "md" : "lg"}
              variant="filled"
              disabled={isDisabled}
              onClick={
                paymentMethod === "bank_transfer"
                  ? () => setShowBankTransferScreen(true)
                  : () => handleConfirmation()
              }
            />
          </div>
        )}
      </SideSheet.Actions>
    </SideSheet>
  );
};
