import * as RadixDropdown from "@radix-ui/react-dropdown-menu";

import { SwitchBusiness } from "components/SwitchBusiness";
import { Help, Logout, Settings } from "design_system/Icons";

import { useGetRole } from "utils/rbacUtils";

import { colorsV2 } from "constants/colors";
import { routes } from "constants/routes";
import type { MerchantInfo } from "types/MerchantInfo";
import type { Role } from "types/Role";

export type AvatarMenuProps = {
  permissions: Role;
  merchantDetails: MerchantInfo;
};

export const AvatarMenu = ({
  permissions,
  merchantDetails,
}: AvatarMenuProps) => {
  const { hasPermission } = useGetRole();
  const { currentUser, platform } = merchantDetails;

  return (
    <RadixDropdown.Root>
      <RadixDropdown.Trigger asChild>
        <button
          type="button"
          className="AccordionTrigger flex h-40 w-40 items-center justify-center rounded-full bg-surface-2 text-text-color-02"
        >
          <p className="label-small md:label-medium">
            {currentUser?.nameInitials}
          </p>
        </button>
      </RadixDropdown.Trigger>

      <RadixDropdown.Portal>
        <RadixDropdown.Content
          className="AccordionContent z-overall rounded-16 bg-surface-1 p-8 shadow-lg"
          sideOffset={5}
          align="end"
          data-state="open"
        >
          {hasPermission("settings:read", permissions) && (
            <RadixDropdown.Item asChild>
              <a href={routes.MANAGE.ACCOUNT_SETTINGS.INDEX()}>
                <div className="body-medium flex cursor-pointer gap-12 rounded-8 p-8 outline-none focus-within:bg-surface-2 hover:bg-surface-2">
                  <div className="mt-2">
                    <Settings size="18" fill={colorsV2.text[2]} />
                  </div>
                  <div className="space-y-8">
                    <p className="label-medium">Settings</p>
                    <p className="body-small text-text-color-02">
                      Your account settings
                    </p>
                  </div>
                </div>
              </a>
            </RadixDropdown.Item>
          )}

          {platform !== "IOS" && (
            <RadixDropdown.Item asChild>
              <a
                href="https://help.mamopay.com/en/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="body-medium flex cursor-pointer gap-12 rounded-8 p-8 outline-none focus-within:bg-surface-2 hover:bg-surface-2">
                  <div className="mt-2">
                    <Help size="18" fill={colorsV2.text[2]} />
                  </div>
                  <div className="space-y-8">
                    <p className="label-medium">Support</p>
                    <p className="body-small text-text-color-02">Help center</p>
                  </div>
                </div>
              </a>
            </RadixDropdown.Item>
          )}

          <SwitchBusiness noDropdown={true} />

          <RadixDropdown.Item asChild>
            <a href={routes.MANAGE.SESSIONS.LOGOUT()} data-method="delete">
              <div className="body-medium flex cursor-pointer gap-12 rounded-8 p-8 outline-none focus-within:bg-surface-2 hover:bg-surface-2">
                <div className="mt-2">
                  <Logout size="18" fill={colorsV2.accent.red[1]} />
                </div>
                <p className="label-medium text-accent-red-01">Logout</p>
              </div>
            </a>
          </RadixDropdown.Item>
        </RadixDropdown.Content>
      </RadixDropdown.Portal>
    </RadixDropdown.Root>
  );
};
