"use client";

import { useQuery } from "@tanstack/react-query";

import { accountsRepository } from "repositories/accounts/accounts.repository";
import { ACCOUNTS_QUERY_KEYS } from "repositories/accounts/keys";

export const useFetchAllBusinesses = () => {
  return useQuery({
    queryKey: ACCOUNTS_QUERY_KEYS.FETCH_ALL_BUSINESSES,
    queryFn: async () => {
      const response = await accountsRepository.accounts();

      if (!response.success && "error" in response) {
        accountsRepository.sentryLogError({
          error: response.error.message,
          feature: "accounts",
          functionName: "accounts",
          data: JSON.stringify(response.error),
        });
      }

      return response;
    },
  });
};
